function isOwner(user) {
  return user?.role?.toLowerCase() === "adminowner";
}

function isManager(user) {
  const role = user?.role?.toLowerCase();
  return role === "adminowner" || role === "adminmanager";
}
function isAdmin(user) {
  return user?.role?.toLowerCase().includes("admin");
}
function canReferee(user) {
  const role = user?.role?.toLowerCase();
  return role?.includes("admin") || role?.includes("referee");
}
function isPlayer(user) {
  return user?.role?.toLowerCase().includes("player");
}
function isCaptain(user) {
  return user?.role?.toLowerCase().includes("captain");
}

export { isOwner, isManager, isAdmin, canReferee, isPlayer, isCaptain };
