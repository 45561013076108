import React from "react";
import WarningHeader from "../common/pageComponents/warningHeader";

const LiveScorecard = ({ liveScorecards, outOfSync, onSync, org }) => {
  if (!liveScorecards) return null;

  const syncButton = (text) => (
    <button className="btn btn-info btn-sm btn-block" onClick={() => onSync()}>
      {text}
    </button>
  );

  return (
    <div className="pop-box text-center">
      {outOfSync ? (
        <div>
          <WarningHeader>
            <b>Scorecard is out of sync</b>
          </WarningHeader>
          {syncButton("Sync Now")}
        </div>
      ) : org?.misc?.userReportedStats ? (
        syncButton("Get Latest User Reported Events")
      ) : (
        <b>This scorecard is live and in sync</b>
      )}
    </div>
  );
};

export default LiveScorecard;
