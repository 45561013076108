import React, { useState } from "react";

import BasicModal from "./basicModal";
import CustomConfirm from "../customs/customConfirm";
import allowables from "../../../utils/allowables";
import TooltipButton from "../tooltip/tooltipButton";
import MiniHeader from "./miniHeader";

const MultiSelect = ({
  data,
  header,
  keyValue,
  columnsToShow,
  onSubmit,
  onSubmit2,
  title,
  title2,
  type,
  children,
  minRowHeight,
  buttonIcon,
  buttonClass,
  tooltipText,
  tooltipDirection,
  buttonTestId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirm2Open, setConfirm2Open] = useState(false);

  const handleItemSelect = (item) => {
    let items = [...selectedItems];
    const index = items.findIndex((i) => i === item[keyValue]);
    if (index >= 0) {
      items.splice(index, 1);
    } else items.push(item[keyValue]);
    setSelectedItems(items);
  };

  const handleSubmit = () => {
    setConfirmOpen(false);
    onSubmit(selectedItems);
  };
  const handleSubmit2 = () => {
    setConfirm2Open(false);
    onSubmit2(selectedItems);
  };

  const buttonColor = type === "delete" ? "dark" : "secondary";

  return (
    <React.Fragment>
      <TooltipButton
        buttonClass={`btn ${
          buttonClass || "btn-sm btn-block"
        } btn-${buttonColor}`}
        onClick={() => setIsOpen(!isOpen)}
        tooltipText={tooltipText}
        tooltipDirection={tooltipDirection || "bottom"}
        testId={buttonTestId}
      >
        {buttonIcon ? buttonIcon : "Multi " + allowables.capLetterOne(type)}
      </TooltipButton>
      <BasicModal isOpen={isOpen} onClose={setIsOpen}>
        <MiniHeader>
          {allowables.capLetterOne(type)} Multiple{" "}
          {allowables.capLetterOne(header || title)}
        </MiniHeader>
        <div className="form-divided-section">
          <h5 className="text-center">
            Select all the {header || title} you want to {type}
          </h5>
          <h6 className="text-center">
            {selectedItems.length} {header || title} selected
          </h6>
          <div className="row">
            <div className="col">
              <button
                className={`btn btn-block btn-md btn-${buttonColor}`}
                onClick={() => {
                  setConfirmOpen(true);
                }}
                disabled={selectedItems.length === 0}
              >
                {allowables.capLetterOne(type)} Selected{" "}
                {allowables.capLetterOne(title)}
              </button>
            </div>
            {onSubmit2 && title2 && (
              <div className="col">
                <button
                  className={`btn btn-block btn-md btn-${buttonColor}`}
                  onClick={() => {
                    setConfirm2Open(true);
                  }}
                  disabled={selectedItems.length === 0}
                >
                  {allowables.capLetterOne(type)} Selected{" "}
                  {allowables.capLetterOne(title2)}
                </button>
              </div>
            )}
          </div>
          {children}
          <div className="row">
            {columnsToShow.map((c) => {
              return (
                <div className="col text-center" key={c.label}>
                  <h5>
                    <b>{c.label}</b>
                  </h5>
                </div>
              );
            })}
          </div>
          {data.map((d) => {
            const isSelected = selectedItems.find((i) => i === d[keyValue]);
            return (
              <div
                className={
                  "row clickable" + (isSelected ? " bg-info text-white" : "")
                }
                key={d[keyValue]}
                onClick={() => handleItemSelect(d)}
                style={{
                  borderBottom: "1px solid black",
                  minHeight: minRowHeight,
                }}
              >
                {columnsToShow.map((c) => {
                  return (
                    <div className="col text-center" key={c.label}>
                      {c.content(d)}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </BasicModal>
      <CustomConfirm
        dialog={`Are you sure you want to ${type} the selected ${title}?`}
        callback={handleSubmit}
        isOpen={confirmOpen}
        close={setConfirmOpen}
        focused={true}
        yesNo={true}
      />
      <CustomConfirm
        dialog={`Are you sure you want to ${type} the selected ${title2}?`}
        callback={handleSubmit2}
        isOpen={confirm2Open}
        close={setConfirm2Open}
        focused={true}
        yesNo={true}
      />
    </React.Fragment>
  );
};

export default MultiSelect;
