import React, { useContext } from "react";

import Table from "../common/table/table";
import { CLink } from "../common/customs/customLinks";
import EventImage from "../assignedMatch/eventImage";
import allowables from "../../utils/allowables";
import OrgContext from "../../context/orgContext";
import ExcelDownload from "../common/pageComponents/excelDownload";

const UserInformationTable = ({ users, onSort, sortColumn }) => {
  const org = useContext(OrgContext);
  let columns = [
    {
      key: "image",
      label: (
        <ExcelDownload
          data={users}
          columns={[
            "name",
            "email",
            "teamName",
            "shirtSize",
            "genderIdentity",
            "pronouns",
            "imageUploadedBy",
          ]}
          dataName="User Information"
        />
      ),

      content: (u) => <EventImage player={u} fromUser={true} />,
    },
    {
      path: "name",
      label: "Name",
      content: (u) =>
        u.role.includes("player") && u.playerID ? (
          <CLink path={`/players?info&q=${u.playerID?._id}`}>
            {allowables.splitName(u.name)}
          </CLink>
        ) : (
          allowables.splitName(u.name)
        ),
    },
    {
      path: "role",
      label: "Role",
      content: (u) => allowables.translateRole(u.role, u.sport),
    },
    {
      path: "teamName",
      label: "Team Name",
      content: (u) => (
        <CLink path={`/teams?info&q=${u.teamID}`}>{u.teamName}</CLink>
      ),
    },
  ];

  const pi = org?.misc?.requiredPersonalInformation;
  if (pi) {
    if (pi.shirtSizeRequired)
      columns.push({ path: "shirtSize", label: "Shirt Size" });
    if (pi.genderIdentityRequired)
      columns.push({ path: "genderIdentity", label: "Gender Identity" });
    if (pi.pronounsRequired)
      columns.push({ path: "pronouns", label: "Pronouns" });
  }
  if (org?.misc?.userImages === "Yes") {
    columns.push({
      path: "imageUploadedBy",
      label: "Image",
    });
  }

  return (
    <Table
      columns={columns}
      data={users}
      sortColumn={sortColumn}
      onSort={onSort}
      keyProperty={"_id"}
      tableClass="table-custom"
      headerClass="thead-custom"
      bodyClass="tbody-custom"
      highlightOnHover={true}
    />
  );
};

export default UserInformationTable;
