import React from "react";

import { CLinkSVG } from "../common/customs/customLinks";
import { getTextX } from "../../utils/bracketsUtil";
import allowables from "../../utils/allowables";

const MatchLink = ({ match, textAnchor, width, height, onSelectMatch }) => {
  const X = getTextX(textAnchor, width);

  return (
    <CLinkSVG
      path={`/match${match.matchComplete === 0 ? "es" : "review"}?q=${
        match._id
      }`}
      x={X}
      y={height / 2 + 5}
      className="svg-final-text svg-match-spacer"
      style={{ textAnchor, fontSize: height / 7 }}
      clickHandler={onSelectMatch}
    >
      #{match.matchNumber}: {allowables.shortDate(match.dateTime)}
    </CLinkSVG>
  );
};

export default MatchLink;
