import React, { Component } from "react";
import toast from "../../utils/toast";
import Table from "../common/table/table";
import { getCurrentUser } from "../../services/userService";
import {
  makeTableButton,
  combineButtons,
  makeCustomTableButton,
} from "./../../utils/buttons";
import { addVideoUrl } from "../../services/mediaService";
import allowables from "../../utils/allowables";
import { CLink } from "../common/customs/customLinks";
import { FaClipboardList } from "react-icons/fa";
import { RiFileSearchLine } from "react-icons/ri";
import EventImage from "../assignedMatch/eventImage";
import Tooltip from "../common/tooltip/tooltip";
import TeamLogo from "../common/pageComponents/teamLogo";
import HeaderContext from "./../../context/headerContext";
import IconRender from "../common/icons/iconRender";
import ReviewHeaderSegment from "../matchReview/reviewHeaderSegment";
import ModalVideoViewer from "../media/modalVideoViewer";
import CustomPrompt from "../common/customs/customPrompt";
import { canReferee } from "../../utils/permissionsUtil";

class MatchesTable extends Component {
  static contextType = HeaderContext;
  state = {
    teams: allowables.teams(getCurrentUser().sport),
    user: getCurrentUser(),
    videoInfo: {
      modalOpen: false,
      url: "",
      header: null,
    },
    videoUrlOpen: false,
    selectedMatch: null,
  };

  handleViewVideo = (match) => {
    const header = (
      <div className="text-center">
        <h5>
          <b>{this.getVideoTypeName()}</b>
        </h5>
        <ReviewHeaderSegment
          data={match}
          spectate={true}
          teams={allowables.teams(match.sport)}
          hideCompletionInfo={true}
        />
      </div>
    );
    this.setState({
      videoInfo: {
        modalOpen: true,
        url: match.videoUrl,
        header,
      },
    });
  };

  handleOpenVideoUrlPrompt = (match) => {
    this.setState({
      selectedMatch: match,
      videoInfo: {
        url: match.videoUrl,
      },
      videoUrlOpen: true,
    });
  };

  handleAddVideoUrl = async (url) => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const res = await addVideoUrl(this.state.selectedMatch._id, url);
    if (res.status === 200) {
      toast.success("Video link added");
      this.setState({ videoUrlOpen: false });
      await this.props.onRefresh();
    } else {
      this.setState({ videoUrlOpen: true });
      toast.error(res.data);
    }
    this.context.setLoading(false);
  };

  getVideoTypeName = (lowerCase) => {
    const name =
      "Match " + (this.props.isReviewTable ? "Highlights" : "Preview");
    return lowerCase ? name.toLowerCase() : name;
  };

  columns = [
    {
      path: "dateTime",
      label: "Date & Time",
      content: (m) =>
        m.dateTime ? (
          <Tooltip content={<span>All times are local</span>} direction="down">
            <p>
              {allowables.dateTime(m.dateTime).date}{" "}
              <b>{allowables.dateTime(m.dateTime).time}</b>
            </p>
          </Tooltip>
        ) : null,
    },
    {
      path: this.state.teams[0] + "TeamLogo",
      label: "",
      content: (m) =>
        m[this.state.teams[0] + "TeamLogo"] ? (
          <TeamLogo
            team={m[this.state.teams[0] + "TeamLogo"]}
            name={m[this.state.teams[0] + "TeamName"]}
            size={40}
          />
        ) : (
          ""
        ),
    },
    {
      path: this.state.teams[0] + "TeamName",
      label:
        this.state.teams[0].charAt(0).toUpperCase() +
        this.state.teams[0].slice(1) +
        " Team",
      content: (m) => (
        <CLink path={`/teams?info&q=${m[this.state.teams[0] + "TeamID"]}`}>
          {m[this.state.teams[0] + "TeamName"]}
        </CLink>
      ),
    },
    {
      path: this.state.teams[0] + "TeamGoals",
      label: "",
      content: (m) => {
        const showPKs = m.homeTeamPKs > 0 || m.awayTeamPKs > 0;
        return (
          <div>
            {m[this.state.teams[0] + "TeamGoals"]}
            {showPKs && (
              <small>&nbsp;({m[this.state.teams[0] + "TeamPKs"]})</small>
            )}
          </div>
        );
      },
    },
    {
      path: this.state.teams[1] + "TeamGoals",
      label: "",
      content: (m) => {
        const showPKs = m.homeTeamPKs > 0 || m.awayTeamPKs > 0;
        return (
          <div>
            {showPKs && (
              <small>({m[this.state.teams[1] + "TeamPKs"]})&nbsp;</small>
            )}
            {m[this.state.teams[1] + "TeamGoals"]}
          </div>
        );
      },
    },
    {
      path: this.state.teams[1] + "TeamName",
      label:
        this.state.teams[1].charAt(0).toUpperCase() +
        this.state.teams[1].slice(1) +
        " Team",
      content: (m) => (
        <CLink path={`/teams?info&q=${m[this.state.teams[1] + "TeamID"]}`}>
          {m[this.state.teams[1] + "TeamName"]}
        </CLink>
      ),
    },
    {
      path: this.state.teams[1] + "TeamLogo",
      label: "",
      content: (m) =>
        m[this.state.teams[1] + "TeamLogo"] ? (
          <TeamLogo
            team={m[this.state.teams[1] + "TeamLogo"]}
            name={m[this.state.teams[1] + "TeamName"]}
            size={40}
          />
        ) : (
          ""
        ),
    },
    {
      path: "fieldID",
      label: "Field",
      content: (m) =>
        m.fieldID && this.props.onFieldClick ? (
          <div
            className="svg-link"
            onClick={() => this.props.onFieldClick(m.fieldID)}
          >
            {m.fieldName}
          </div>
        ) : (
          m.fieldName
        ),
    },
    {
      path: "divisionName",
      label: "Division",
      content: (m) => (
        <CLink path={`/divisions?info&q=${m.divisionID}`}>
          {m.divisionName}
        </CLink>
      ),
    },
    {
      path: "type",
      label: "Match Type",
      content: (m) => (
        <CLink
          path={`/reports?${
            m.type.includes("Playoff") ? "brackets" : "standings"
          }=${m.divisionID}`}
        >
          {m.type}
        </CLink>
      ),
    },
    { path: "round", label: "Round" },
    { path: "groupName", label: "Group" },
    {
      path: "refereeName",
      label: allowables.refOrUmp(this.state.user.sport),
      content: (m) => <div>{allowables.splitName(m.refereeName)}</div>,
    },
    {
      path: "refereeAvatar",
      label: "",
      content: (m) => <EventImage player={m.refereeID} />,
    },
    {
      path: "videoUrl",
      label: "",
      content: (m) =>
        m.videoUrl ? (
          <div className="clickable" onClick={() => this.handleViewVideo(m)}>
            <Tooltip
              content={<span>Watch {this.getVideoTypeName()}</span>}
              direction="left"
            >
              <IconRender name="video" className="icon-raised" />
            </Tooltip>
          </div>
        ) : null,
    },
  ];

  constructor(props) {
    super(props);
    if (!props.isScoreboard) {
      const user = getCurrentUser();
      const matchStarted = localStorage.getItem("matchStarted");
      const matchID = localStorage.getItem("_id");
      let buttons = [];
      if (props.isReviewTable) {
        this.columns.unshift(
          makeCustomTableButton(
            function () {
              return <RiFileSearchLine />;
            },
            function (x) {
              return x.matchAccepted === 1 ? "View: " : "Review: ";
            },
            function (x) {
              return x.matchAccepted === 0 ? "secondary" : "info";
            },
            "select",
            "matchreview",
            "",
            "",
            function (x) {
              const teams = allowables.teams(x.sport);
              return (
                <div>
                  {`${x[teams[0] + "TeamName"]}`}{" "}
                  <i>{teams[0] === "home" ? "vs" : "at"}</i>{" "}
                  {`${x[teams[1] + "TeamName"]}`}
                </div>
              );
            }
          )
        );
      } else {
        if (canReferee(user))
          this.columns.unshift(
            makeCustomTableButton(
              function (x) {
                return matchStarted && matchID === x._id ? (
                  <FaClipboardList />
                ) : (
                  allowables.ballIcon(allowables.translateSportName(props.org))
                );
              },
              function (x) {
                return matchStarted && matchID === x._id
                  ? "Continue: "
                  : "Start: ";
              },
              function (x) {
                return matchStarted && matchID === x._id ? "secondary" : "info";
              },
              "start",
              `assignedmatches`,
              "",
              props.dummy ? null : "refereeID",
              function (x) {
                const teams = allowables.teams(x.sport);
                return (
                  <div>
                    {`${x[teams[0] + "TeamName"]}`}{" "}
                    <i>{teams[0] === "home" ? "vs" : "at"}</i>{" "}
                    {`${x[teams[1] + "TeamName"]}`}
                  </div>
                );
              },
              "start-match"
            )
          );
        else
          this.columns.unshift({
            path: "view",
            label: "",
            content: (m) => (
              <CLink path={`/matches?q=${m._id}`}>
                <IconRender name="info" className="icon-md icon-mobile" />
              </CLink>
            ),
          });
      }
      if (user && user.role?.includes("admin")) {
        if (!props.isReviewTable) {
          buttons.push(makeTableButton("delete", props.onDelete));
          buttons.push(makeTableButton("edit", "matches"));
        }
        buttons.push(
          makeTableButton(
            "add",
            this.handleOpenVideoUrlPrompt,
            "",
            "",
            `${this.getVideoTypeName()} URL`
          )
        );
        if (props.org?.misc?.userReportedStats) {
          this.columns.push({
            path: "userReportedStatsLocked",
            label: <IconRender name="lock" />,
            content: (m) => (
              <IconRender
                name={m.userReportedStatsLocked ? "lock" : "unlock"}
              />
            ),
          });
          buttons.push(makeTableButton("lock", props.onToggleStatLock));
        }
        this.columns.push(combineButtons(buttons));
      }
    }
  }

  render() {
    const { matchesByPage, onSort, sortColumn } = this.props;

    return (
      <div>
        <Table
          columns={this.columns}
          data={matchesByPage}
          sortColumn={sortColumn}
          onSort={onSort}
          keyProperty={"_id"}
          tableClass="table-custom"
          headerClass="thead-custom"
          bodyClass="tbody-custom"
          highlightOnHover={true}
        />
        <ModalVideoViewer
          isOpen={this.state.videoInfo.modalOpen}
          onClose={() => this.setState({ videoInfo: { modalOpen: false } })}
          videoUrl={this.state.videoInfo.url}
          header={this.state.videoInfo.header}
        />
        <CustomPrompt
          dialog={`Enter ${this.getVideoTypeName(true)} URL%
          Most video upload services are supported (YouTube, Twitch, Vimeo, Facebook, etc.)`}
          callback={this.handleAddVideoUrl}
          isOpen={this.state.videoUrlOpen}
          close={() => this.setState({ videoUrlOpen: false })}
          id="videoUrlOpen"
          initialUserInput={this.state.selectedMatch?.videoUrl}
          minWidth={400}
          split="%"
          centerAllText={true}
        />
      </div>
    );
  }
}

export default MatchesTable;
