import React from "react";

import allowables from "../../../utils/allowables";
import IconRender from "../icons/iconRender";

const HorizontalTabs = ({ tabs, selectedTab, onSelectTab, perRow }) => {
  const numberOfRows = perRow
    ? (tabs.length - (tabs.length % perRow)) / perRow + 1
    : 1;
  let splitTabs = [];
  let j = 0;
  for (let i = 1; i <= numberOfRows; i++) {
    splitTabs.push(tabs.slice(j, i * perRow));
    j = i * perRow;
  }
  splitTabs = perRow ? splitTabs : [tabs];

  return (
    <div className="pop-box">
      {splitTabs.map((s, i) => {
        return (
          <div className="row" key={i}>
            {s.map((tab) => {
              const TabIcon = tab.iconName ? (
                <IconRender name={tab.iconName} />
              ) : (
                <tab.icon /> || null
              );
              const color =
                tab.name === selectedTab ? "main-theme font-weight-bold" : "";
              return (
                <div
                  className="col text-center clickable"
                  onClick={() => onSelectTab(tab)}
                  style={{ height: 50 }}
                  key={tab.name}
                  aria-label={`horizontal-tab-${tab.name}`}
                >
                  <div className={color}>
                    {allowables.capLetterOne(tab.name)}
                  </div>
                  <div className={color}>{TabIcon}</div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default HorizontalTabs;
