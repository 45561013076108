import React, { useState } from "react";
import BasicModal from "../common/pageComponents/basicModal";
import { popupStyle } from "../../utils/styleUtil";
import MiniHeader from "../common/pageComponents/miniHeader";
import SearchBox from "../common/dataSorting/searchBox";
import allowables from "../../utils/allowables";
import WarningHeader from "../common/pageComponents/warningHeader";
import HorizontalButtons from "../common/dataSorting/horizontalButtons";

const ApplyDiscount = ({
  isOpen,
  setIsOpen,
  discount,
  org,
  paymentItems,
  onApplyDiscount,
}) => {
  const [search, setSearch] = useState("");
  const [selection, setSelection] = useState("All");

  if (!discount || discount._id === "new") return null;

  const getPageData = () => {
    let items = [];
    if (paymentItems)
      paymentItems.forEach((i) => {
        let item = { ...i };
        if (discount.appliesToItems.includes(i._id)) item.applied = true;
        if (
          (allowables
            .splitName(item.name)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
            i.teamName?.toLowerCase().includes(search.toLowerCase())) &&
          (selection === "All" ||
            (selection === "Applied" && !!item.applied) ||
            (selection === "Not Applied" && !item.applied))
        )
          items.push(item);
      });
    return items;
  };

  return (
    <BasicModal
      style={popupStyle}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <MiniHeader>
        Apply <i>{discount.name}</i> Discount to {org.stripe.whoPays}s
      </MiniHeader>
      <div className="form-divided-section">
        {!discount.active ? (
          <div>
            <WarningHeader>This discount is not active</WarningHeader>
            <p className="text-center">
              You can still apply the discount but{" "}
              {org.stripe.whoPays.toLowerCase()}s will not be able to use it.
            </p>
          </div>
        ) : (
          <p className="text-center">
            <small>
              Click a {org.stripe.whoPays.toLowerCase()} to apply this discount
              to their future payment. Only one discount can be applied to a
              player at a time.
            </small>
          </p>
        )}
        <HorizontalButtons
          buttons={["All", "Applied", "Not Applied"]}
          onClick={(val) => setSelection(val)}
          selectedType={selection}
        />
        <SearchBox
          value={search}
          onChange={setSearch}
          placeholder={`Search by ${org.stripe.whoPays.toLowerCase()} ${
            org.stripe.whoPays === "Player" ? "or team " : ""
          }name...`}
        />
        {getPageData().map((item, idx) => (
          <div
            key={idx}
            className={
              "cardview-card clickable" +
              (item.applied ? " bg-info text-white" : "")
            }
            onClick={() => onApplyDiscount(item)}
          >
            <div className="cardview-text">
              <b>{allowables.splitName(item.name)}</b>
              {org?.stripe?.whoPays === "Player" ? (
                <>
                  <br />
                  {item.teamName}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
    </BasicModal>
  );
};

export default ApplyDiscount;
