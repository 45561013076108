import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import toast from "../../utils/toast";
import HeaderContext from "../../context/headerContext";
import { getCurrentUser } from "../../services/userService";
import WarningHeader from "../common/pageComponents/warningHeader";
import MiniHeader from "../common/pageComponents/miniHeader";
import IconRender from "../common/icons/iconRender";
import { bulkAddPlayers } from "../../services/playerService";
import { navigateTo } from "../common/customs/customLinks";

const newPlayer = { firstName: "", lastName: "", email: "" };

class MassInviteForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      players: [{ ...newPlayer }],
    },
    errors: {},
  };

  schema = {
    players: Joi.array().items({
      firstName: Joi.string().max(50).required().label("First Name"),
      lastName: Joi.string().max(50).required().label("Last Name"),
      email: Joi.string().email().optional().label("Email").allow(""),
    }),
  };

  handleAddRow = (e) => {
    e.preventDefault();
    const { data } = this.state;
    data.players.push({ ...newPlayer });
    this.setState({ data });
  };
  handleDeleteRow = (idx) => {
    const { data } = this.state;
    const curPlayers = [...data.players];
    curPlayers.splice(idx, 1);
    data.players = curPlayers;
    this.setState({ data });
  };

  handleInputChange = (event, idx) => {
    const { data } = this.state;
    const curValue = { ...data.players[idx] };
    const { name, value } = event.target;
    curValue[name.replace(idx, "")] = value;
    data.players.splice(idx, 1, curValue);

    return {
      currentTarget: {
        name: "players",
        value: data.players,
      },
    };
  };

  doSubmit = async () => {
    const { data } = this.state;
    const players = [...data.players];
    let completedPlayers = [];
    players.forEach((player) => {
      if (player.firstName || player.lastName || player.email)
        completedPlayers.push(player);
    });
    data.players = completedPlayers.length
      ? completedPlayers
      : [{ ...newPlayer }];

    this.setState({ data });
    const errors = this.validate();
    if (errors) return this.setState({ errors });

    this.context.setLoading(true);
    const res = await bulkAddPlayers(data.players);
    if (res.status === 200) {
      toast.success("Players Added");
      const user = getCurrentUser();
      navigateTo(
        `teams?info&q=${user?.teamID}`,
        this.props.history,
        this.context
      );
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  render() {
    const user = getCurrentUser();
    return (
      <div className="centered-small-input-area">
        <MiniHeader>{user.teamName}</MiniHeader>
        <div className=" form-divided-section">
          <form onSubmit={this.handleSubmit}>
            {this.renderValidatedButton("Add Players", "btn-block")}
            <WarningHeader>{this.state.errors.players}</WarningHeader>
            {this.state.data.players.map((player, idx) => (
              <div className="cardview-card" key={idx}>
                <div className="row">
                  <div className="col">
                    {this.renderInput(
                      "firstName",
                      "First Name",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      (e) => this.handleInputChange(e, idx),
                      false,
                      {
                        index: idx,
                        property: "players",
                        name: "firstName" + idx,
                      }
                    )}
                  </div>
                  <div className="col">
                    {this.renderInput(
                      "lastName",
                      "Last Name",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      (e) => this.handleInputChange(e, idx),
                      false,
                      {
                        index: idx,
                        property: "players",
                        name: "lastName" + idx,
                      }
                    )}
                  </div>
                </div>

                {this.renderInput(
                  "email",
                  "Email",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  (e) => this.handleInputChange(e, idx),
                  false,
                  { index: idx, property: "players", name: "email" + idx }
                )}
                <div className="row">
                  <div className="col text-left">
                    {this.state.data.players.length > 1 ? (
                      <div className="text-left">
                        <button
                          aria-label="delete-row"
                          className="btn btn-md btn-dark"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleDeleteRow(idx);
                          }}
                        >
                          <IconRender name="delete" />
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="col text-right">
                    {idx === this.state.data.players.length - 1 ? (
                      <div className="text-right">
                        <button
                          aria-label="add-row"
                          className="btn btn-md btn-info"
                          onClick={this.handleAddRow}
                        >
                          <IconRender name="plus" />
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </form>
        </div>
      </div>
    );
  }
}

export default MassInviteForm;
