import React, { useState } from "react";

import BasicModal from "./basicModal";
import CustomConfirm from "../customs/customConfirm";
import Checkbox from "../form/checkBox";
import TooltipButton from "../tooltip/tooltipButton";
import MiniHeader from "./miniHeader";

const MultiEdit = ({
  data,
  keyValue,
  columnsToShow,
  editComponents,
  onSubmit,
  title,
  type,
  children,
  minRowHeight,
  noHeaders,
  buttonIcon,
  buttonClass,
  tooltipText,
  tooltipDirection,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleItemSelect = (item) => {
    let items = [...selectedItems];
    const index = items.findIndex((i) => i === item[keyValue]);
    if (index >= 0) {
      items.splice(index, 1);
    } else items.push(item[keyValue]);
    setSelectedItems(items);
  };

  const handleSubmit = async () => {
    setIsOpen(false);
    const res = await onSubmit(selectedItems);
    if (res) setSelectedItems([]);
  };

  return (
    <div>
      <TooltipButton
        buttonClass={`btn ${buttonClass || `btn btn-sm btn-block btn-success`}`}
        onClick={() => setIsOpen(!isOpen)}
        tooltipText={tooltipText}
        tooltipDirection={tooltipDirection || "bottom"}
      >
        {buttonIcon ? buttonIcon : title}
      </TooltipButton>
      <BasicModal isOpen={isOpen} onClose={setIsOpen}>
        <MiniHeader>{title}</MiniHeader>
        <div className="form-divided-section">
          <div className="sticky-top bg-light" style={{ padding: 10 }}>
            <div className="row">
              <div className="col">
                <h6 className="text-center">
                  {selectedItems.length
                    ? `${selectedItems.length} ${type} selected`
                    : `Select at least one item to update`}
                </h6>
              </div>
              <div className="col">
                <div className="text-right" style={{ marginBottom: 5 }}>
                  <button
                    className={`btn btn-sm btn-${
                      selectedItems.length ? "dark" : "secondary"
                    }`}
                    onClick={() =>
                      setSelectedItems(
                        selectedItems.length ? [] : data.map((d) => d[keyValue])
                      )
                    }
                  >
                    {selectedItems.length
                      ? "Clear All Selections"
                      : "Select All"}
                  </button>
                </div>
              </div>
            </div>
            <button
              className={`btn btn-block btn-md btn-info`}
              onClick={() => setConfirmOpen(true)}
              disabled={selectedItems.length === 0}
            >
              Submit Changes
            </button>
          </div>
          {children}
          <hr className="custom" />
          {!noHeaders && (
            <div className="row">
              {columnsToShow.map((c) => {
                return (
                  <div className="col text-center" key={c.label}>
                    <h5>
                      <b>{c.label}</b>
                    </h5>
                  </div>
                );
              })}
            </div>
          )}
          {data.map((d, idx) => {
            const isSelected = selectedItems.find((i) => i === d[keyValue]);
            return (
              <div key={idx}>
                <div
                  className="row"
                  style={{
                    minHeight: minRowHeight,
                  }}
                >
                  <Checkbox
                    id="selected"
                    onChange={() => handleItemSelect(d)}
                    value={!!isSelected}
                  />
                  {columnsToShow.map((c) => {
                    return (
                      <div className="col text-center" key={c.label}>
                        {c.content(d)}
                      </div>
                    );
                  })}
                </div>
                {editComponents.map((ec) => (
                  <div key={ec.props.name}>
                    <ec.component
                      {...ec.props}
                      value={
                        ec.props.valueFunc
                          ? ec.props.valueFunc(d)
                          : d[ec.props.value]
                      }
                      _id={d._id}
                      onChange={(event) => {
                        ec.props.onChange(event, d);
                      }}
                    />
                  </div>
                ))}
                <hr className="custom" />
              </div>
            );
          })}
        </div>
      </BasicModal>
      <CustomConfirm
        dialog={`Are you sure you want to ${title}?`}
        callback={handleSubmit}
        isOpen={confirmOpen}
        close={setConfirmOpen}
        focused={true}
        yesNo={true}
      />
    </div>
  );
};

export default MultiEdit;
