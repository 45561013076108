import React, { useEffect, useState } from "react";

import { mapSoccerEvents } from "../../utils/soccerFunctions";
import { mapFootballEvents } from "../../utils/footballFunctions";
import { mapDiamondEvents } from "../../utils/diamondFunctions";
import ListSection from "../common/pageComponents/listSection";
import CustomConfirm from "./../common/customs/customConfirm";
import { getCurrentUser } from "../../services/userService";
import { canReferee, isCaptain } from "../../utils/permissionsUtil";

const EventsTab = ({
  chronoEventsNames,
  match,
  sport,
  onUndo,
  onEdit,
  spectate,
}) => {
  const user = getCurrentUser();
  const [dialog, setDialog] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [alertRemoveOpen, setAlertRemoveOpen] = useState(false);
  const [alertOptions, setAlertOptions] = useState([
    { label: "Cancel", className: "btn btn-sm btn-dark" },
    { label: "Edit", className: "btn btn-sm btn-light" },
  ]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const header =
    chronoEventsNames?.length > 0
      ? match.userReportedStatsLocked
        ? "This scorecard is locked to user updates"
        : "Click Event to Edit"
      : "There are no events";
  const mapFunction = sport.toLowerCase().includes("soccer")
    ? mapSoccerEvents
    : sport.toLowerCase().includes("football")
    ? mapFootballEvents
    : sport.toLowerCase().includes("diamond")
    ? mapDiamondEvents
    : () => {
        return [];
      };

  const handleAlert = (event) => {
    if (spectate || !eventBelongsToUser(event)) return;
    setSelectedEvent(event);
    setDialog(event.text);
    setConfirmOpen(true);
  };

  const handleOptionClick = (option) => {
    switch (option.label) {
      case "Edit":
        onEdit(selectedEvent);
        break;
      case "Remove":
        handleAlertRemove();
        break;
      default:
    }

    setConfirmOpen(false);
  };

  const handleAlertRemove = () => {
    setDialog(`REMOVE: ${selectedEvent.text}`);
    setAlertRemoveOpen(true);
  };

  const eventBelongsToUser = (event) => {
    if (canReferee(user)) return true;
    return (
      (event?.eventType?.includes("home") &&
        user?.teamID === match?.homeTeamID) ||
      (event?.eventType?.includes("away") &&
        user?.teamID === match?.awayTeamID) ||
      event?.teamID === user?.teamID
    );
  };

  useEffect(() => {
    const ao = [...alertOptions];
    if (onUndo) {
      ao.push({ label: "Remove", className: "btn btn-sm btn-info" });
    }
    setAlertOptions(ao);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUndo]);

  return (
    <div>
      {!spectate && (
        <h5 className="text-center">
          <b>{header}</b>
        </h5>
      )}
      <ListSection
        items={mapFunction(match, chronoEventsNames)}
        onClick={match?.matchAccepted ? null : handleAlert}
        ariaLabel="match-event"
        spectate={spectate}
        splitLines={"%s%"}
        highlightRow={(event) =>
          !match.matchAccepted &&
          eventBelongsToUser(event) &&
          isCaptain(user) &&
          (event._id === "unk" ||
            event.player2ID === "unk" ||
            event.assistID === "unk")
        }
      />
      <CustomConfirm
        dialog={dialog}
        isOpen={confirmOpen}
        callback={handleOptionClick}
        close={setConfirmOpen}
        options={alertOptions}
        split="%s%"
        centerAllText
      />
      <CustomConfirm
        dialog={dialog}
        isOpen={alertRemoveOpen}
        close={setAlertRemoveOpen}
        callback={() => onUndo(selectedEvent)}
        split="%s%"
        centerAllText
      />
    </div>
  );
};

export default EventsTab;
