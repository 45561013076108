import React from "react";

import InfoTooltip from "../common/tooltip/infoTooltip";
import PaidFeature from "../common/tooltip/paidFeature";
import allowables from "../../utils/allowables";
import MiniHeader from "../common/pageComponents/miniHeader";
import QRCodeRender from "../common/pageComponents/qrCode";
import { isManager } from "../../utils/permissionsUtil";

const OrgUserLinks = ({ user, sendRegistrationEmail, qrLinks }) => {
  const freeAgentRegLink = `${allowables.url}/freeagent?id=${user.orgID}`;

  return (
    user.orgID &&
    isManager(user) && (
      <div>
        <MiniHeader>Invite Users</MiniHeader>
        <div className="form-divided-section text-center">
          <InfoTooltip
            header={allowables.refOrUmp(user.sport)}
            className="icon-mobile icon-sm"
            direction="left"
            text={`When the ${allowables
              .refOrUmp(user.sport)
              .toLowerCase()} registers for their account you will be able to assign them matches.
                    The ${allowables
                      .refOrUmp(user.sport)
                      .toLowerCase()} profile is limited to the upcoming matches tab and their profile page.
                    They are able to take charge of the matches assigned to them and submit their scorecards for admin review.
                    If you do not have regular ${allowables
                      .refOrUmp(user.sport)
                      .toLowerCase()}s or don't want to make your ${allowables
              .refOrUmp(user.sport)
              .toLowerCase()}s sign up
                    you can set the username/passcode combo in your Leaguewide Settings to allow scorecards to be completed by ${allowables
                      .refOrUmp(user.sport)
                      .toLowerCase()}s who do not have an account.`}
          />
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => sendRegistrationEmail(qrLinks.referee, "referee")}
          >
            Invite {allowables.refOrUmp(user.sport)}
          </button>
          <br />
          <br />
          <InfoTooltip
            header="Team Captains"
            className="icon-mobile icon-sm"
            direction="left"
            text={`A team captain has the ability to create their own team and will have management control over that team.
                    They can add new players to their team and invite users to register directly as those created players.
                    They can edit names and shirt numbers of their players, and are responsible for submitting post match comments for your review.`}
          />
          <PaidFeature direction="left" />
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => sendRegistrationEmail(qrLinks.player, "captain")}
          >
            Invite Team Captain
          </button>
          <br />
          <br />
          <InfoTooltip
            header="Admins"
            className="icon-mobile icon-sm"
            direction="left"
            text={`You can have as many admins in your league as you need to manage your data.
                    An admin account has the same access level as you to create and review data
                    but they are unable to update leaguewide settings.`}
          />
          <PaidFeature direction="left" />
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => sendRegistrationEmail(qrLinks.admin, "admin")}
          >
            Invite Admin
          </button>
          <br />
          <br />
          <InfoTooltip
            header="Free Agents"
            className="icon-mobile icon-sm"
            direction="left"
            text={`Use this link or the QR code in your recruitment materials to allow team captains and free agents to sign up directly without receiving an invitation.
            The availability of signup options on this page are set in the User Interactions section of your league settings.`}
          />
          <h6>
            <a
              href={freeAgentRegLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>Registration Link</b>
            </a>
          </h6>
          <QRCodeRender value={freeAgentRegLink} />
        </div>
      </div>
    )
  );
};

export default OrgUserLinks;
