import React, { useState } from "react";

import SearchBox from "../common/dataSorting/searchBox";
import MiniHeader from "../common/pageComponents/miniHeader";
import PlayersCardView from "../players/playersCardView";

const SpectatePlayers = ({ players, onHeaderClick, onSelectPlayer }) => {
  const [query, setQuery] = useState("");

  const filterPlayers = () => {
    let filteredPlayers = [...players];
    if (query)
      return filteredPlayers.filter(
        (p) =>
          p.name.toLowerCase().includes(query.toLowerCase()) ||
          p.teamName?.toLowerCase().includes(query.toLowerCase())
      );
    else return filteredPlayers;
  };

  return (
    <React.Fragment>
      <MiniHeader onClick={onHeaderClick}>Players</MiniHeader>
      <div className="form-divided-section fill">
        {players.length > 0 ? (
          <div>
            <SearchBox
              placeholder="Search by player or team name..."
              value={query}
              onChange={setQuery}
            />
            <PlayersCardView
              players={filterPlayers()}
              onSelectPlayer={onSelectPlayer}
            />
          </div>
        ) : (
          <p className="text-center cardview-card">
            There are no players in this division
          </p>
        )}
      </div>
    </React.Fragment>
  );
};

export default SpectatePlayers;
