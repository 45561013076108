import React from "react";
import OrgLogoEditor from "./orgLogoEditor";
import RefereeCodeForm from "./refereeCodeForm";
import UmbrellaForm from "../umbrella/umbrellaForm";
import { isOwner } from "../../utils/permissionsUtil";
import { getCurrentUser } from "../../services/userService";

const OrgOptions = ({
  org,
  openLogo,
  logoOpen,
  openDelete,
  deleteOpen,
  handleDelete,
  navToSettings,
}) => {
  return (
    <div>
      <div>
        <button
          className="btn btn-light btn-sm btn-block"
          onClick={navToSettings}
        >
          Edit Leaguewide Settings
        </button>
        <br />
        <RefereeCodeForm />
        <br />
        <button className="btn btn-sm btn-light btn-block" onClick={openLogo}>
          {logoOpen
            ? "Close Logo Editor"
            : org?.logo
            ? "Update Logo"
            : "Upload Logo"}
        </button>
        {logoOpen && <OrgLogoEditor org={org} />}
        <br />
        <UmbrellaForm org={org} />

        {isOwner(getCurrentUser()) ? (
          <div>
            <br />
            <br />
            <br />
            <button className="btn btn-danger btn-sm" onClick={openDelete}>
              {deleteOpen ? "Close Delete" : "Delete League"}
            </button>
            <br />
            <br />

            {deleteOpen && (
              <button className="btn btn-dark btn-sm" onClick={handleDelete}>
                Are you sure? Purge League
              </button>
            )}
            <br />
            <br />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default OrgOptions;
