import React, { useState, useEffect, useContext } from "react";
import toast from "../../utils/toast";

import {
  getAllLiveScorecards,
  removeLiveScorecard,
} from "../../services/liveScoreService";
import HeaderContext from "../../context/headerContext";
import LiveScorecardsTable from "./liveScorecardsTable";
import CustomConfirm from "../common/customs/customConfirm";

const LiveScorecardsMain = () => {
  const { setLoading } = useContext(HeaderContext);
  const [allScorecards, setAllScorecards] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedScorecard, setSelectedScorecard] = useState(null);

  const getData = async () => {
    setLoading(true);
    const res = await getAllLiveScorecards();
    if (res?.status === 200) {
      setAllScorecards(res.data);
    } else toast.error(res?.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveScorecard = async () => {
    if (!selectedScorecard) return;
    setLoading(true);
    const res = await removeLiveScorecard(selectedScorecard._id);
    if (res?.status === 200) {
      getData();
      toast.success("Live Scorecard Removed");
    } else {
      toast.error(res?.data);
    }
    setLoading(false);
  };

  return (
    <div>
      <button className="btn btn-block btn-info" onClick={getData}>
        Refresh
      </button>
      <LiveScorecardsTable
        scorecards={allScorecards}
        onRemoveScorecard={(scorecard) => {
          setConfirmOpen(true);

          setSelectedScorecard(scorecard);
        }}
      />
      <CustomConfirm
        dialog="Are you sure you want to remove this scorecard?"
        callback={handleRemoveScorecard}
        isOpen={confirmOpen}
        close={setConfirmOpen}
      />
    </div>
  );
};

export default LiveScorecardsMain;
