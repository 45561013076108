import React, { Component } from "react";
import ReactGA from "react-ga4";
import {
  getDivisions,
  deleteDivision,
  multiToggleDivisions,
} from "../../services/divisionService";
import { getSeasons } from "../../services/seasonsService";
import { resetSuspensions } from "../../services/playerService";
import MatchesDivisionSelect from "../matches/matchesDivisionSelect";
import DivisionsTable from "./divisionsTable";
import SearchBox from "../common/dataSorting/searchBox";
import orderBy from "lodash/orderBy";
import { paginate } from "../../utils/paginate";
import Pagination from "../common/dataSorting/pagination";
import { getCurrentUser } from "../../services/userService";
import toast from "../../utils/toast";
import { splitQuery } from "../../utils/splitQuery";
import CustomConfirm from "../common/customs/customConfirm";
import QuickLinks from "../common/pageComponents/quickLinks";
import ExcelDownload from "../common/pageComponents/excelDownload";
import allowables from "../../utils/allowables";
import DivisionsButtonLineup from "./divisionsButtonLineup";
import { ImCheckmark } from "react-icons/im";
import HeaderContext from "../../context/headerContext";
import { CLink } from "../common/customs/customLinks";
import NonFormSelect from "../common/form/nonFormSelect";

class Divisions extends Component {
  static contextType = HeaderContext;
  state = {
    divisions: [],
    seasons: [],
    pageSize: allowables.pageSize,
    currentPage: 1,
    searchQuery: "",
    sortColumn: { path: "division", order: "asc" },
    selectedDivision: null,
    selectedDivisions: [],
    selectedSeasonId: "all",
    resetSuspensionsOpen: false,
    deleteOpen: false,
    deleteDialog: "",
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const user = getCurrentUser();
    const response = await getDivisions(
      {
        callback: this.indicateProgress,
        bar: 0,
      },
      user.role.includes("admin")
    );
    if (response.status === 200) {
      const seasonsRes = await getSeasons(
        { callback: this.indicateProgress, bar: 1 },
        false
      );
      if (seasonsRes.status === 200) {
        this.setState({
          seasons: [{ _id: "all", name: "All Seasons" }, ...seasonsRes.data],
          divisions: response.data,
        });
      } else toast.error(seasonsRes.data);
    } else toast.error(response.data);
    const queryString = splitQuery(this.props.location.search);
    let searchQuery = "";
    let sortColumn = { ...this.state.sortColumn };
    let currentPage = 1;
    queryString.forEach((q) => {
      const each = q.split("=");
      searchQuery = each[0].includes("search") ? each[1] : searchQuery;
      currentPage = each[0].includes("page") ? Number(each[1]) : currentPage;
      sortColumn.path = each[0].includes("path") ? each[1] : sortColumn.path;
      sortColumn.order = each[0].includes("order") ? each[1] : sortColumn.order;
    });
    this.setState({
      searchQuery,
      sortColumn,
      currentPage,
    });
    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  getDownloadData = () => {
    return this.state.divisions.map((d) => {
      return {
        name: d.name,
        maxPlayersPerTeam: d.maxPlayersPerTeam,
        sport: d.sport,
        archiveDate:
          d.archive && d.archive.archiveDate
            ? allowables.formDate(d.archive.archiveDate, true)
            : "",
        editingLocked: d.editingLocked,
        rostersLocked: d.rostersLocked,
      };
    });
  };
  downloadColumns = [
    "name",
    "maxPlayersPerTeam",
    "sport",
    "archiveDate",
    "rostersLocked",
    "editingLocked",
  ];

  pushQueryString = (search, sortColumn, currentPage) => {
    this.props.history.replace(
      `?search=${search}&path=${sortColumn.path}&order=${sortColumn.order}&page=${currentPage}`
    );
  };

  handleSort = (sortColumn) => {
    if (sortColumn.path) {
      this.pushQueryString(
        this.state.searchQuery,
        sortColumn,
        this.state.currentPage
      );
      this.setState({ sortColumn });
    }
  };

  handleSearch = (query) => {
    this.pushQueryString(query, this.state.sortColumn, 1);
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  setDeleteDialog = (division) => {
    this.setState({
      deleteDialog: `Are you sure you want to delete ${division.name}?`,
      selectedDivision: division,
      deleteOpen: true,
    });
  };

  handleDelete = async () => {
    const { selectedDivision: division } = this.state;
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const response = await deleteDivision(division._id, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      ReactGA.event({
        category: "Division",
        action: "delete",
        label: "deleted division from divisions table",
        nonInteraction: false,
      });
      const divisionsResponse = await getDivisions(
        {
          callback: this.indicateProgress,
          bar: 1,
        },
        true
      );
      if (divisionsResponse.status === 200) {
        toast.success("Division deleted");
        this.setState({ divisions: divisionsResponse.data });
      } else toast.error(divisionsResponse.data);
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  multiSelectColumns = [
    { content: (d) => d.name, label: "Name" },
    {
      content: (d) => (d.editingLocked ? <ImCheckmark /> : ""),
      label: "Editing Locked",
    },
    {
      content: (d) => (d.rostersLocked ? <ImCheckmark /> : ""),
      label: "Rosters Locked",
    },
    {
      content: (d) => (d.archive && d.archive.archived ? "Yes" : ""),
      label: "Archived",
    },
  ];

  handleMultiToggle = async (divisionIDs, type) => {
    if (divisionIDs.length === 0)
      return toast.error("You must select at least one division");

    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const response = await multiToggleDivisions(divisionIDs, type, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      toast.success("Divisions toggled");
      const divisionsResponse = await getDivisions(
        {
          callback: this.indicateProgress,
          bar: 1,
        },
        true
      );
      if (divisionsResponse.status === 200)
        this.setState({ divisions: divisionsResponse.data });
      else toast.error(divisionsResponse.data);
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  handleSelectDivision = (division) => {
    let { selectedDivisions } = this.state;
    const index = selectedDivisions.findIndex((d) => division._id === d);
    if (index >= 0) selectedDivisions.splice(index, 1);
    else selectedDivisions.push(division._id);
    this.setState({ selectedDivisions });
  };

  handleResetSuspensions = async () => {
    const { selectedDivisions } = this.state;
    if (selectedDivisions.length === 0)
      return toast.error("Please select at least one division");
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const res = await resetSuspensions(selectedDivisions, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) toast.success("Suspensions reset");
    else toast.error(res.data);
    this.context.setLoading(false);
  };

  handlePageChange = (page) => {
    this.pushQueryString(this.state.searchQuery, this.state.sortColumn, page);
    this.setState({ currentPage: page });
  };

  handlePreviousPage = () => {
    const currentPage = this.state.currentPage;
    this.pushQueryString(
      this.state.searchQuery,
      this.state.sortColumn,
      currentPage - 1
    );
    this.setState({ currentPage: currentPage - 1 });
  };

  handleNextPage = () => {
    const currentPage = this.state.currentPage;
    this.pushQueryString(
      this.state.searchQuery,
      this.state.sortColumn,
      currentPage + 1
    );
    this.setState({ currentPage: currentPage + 1 });
  };

  handleSelectSeason = (event) => {
    this.setState({ searchQuery: "", selectedSeasonId: event.target.value });
  };

  getPageData = () => {
    const { divisions, sortColumn, searchQuery, selectedSeasonId } = this.state;
    let filteredDivisions = divisions;

    if (searchQuery)
      filteredDivisions = divisions.filter((l) =>
        l.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

    if (selectedSeasonId !== "all")
      filteredDivisions = filteredDivisions.filter(
        (d) => d.seasonID?._id === selectedSeasonId
      );

    let sortedDivisions = orderBy(
      filteredDivisions,
      [sortColumn.path],
      [sortColumn.order]
    );
    if (sortColumn.path.includes("season")) {
      sortedDivisions = sortedDivisions.sort((a, b) => {
        let order =
          !a.seasonID && !b.seasonID
            ? 0
            : !a.seasonID
            ? 1
            : !b.seasonID
            ? -1
            : a.seasonID?.name > b.seasonID?.name
            ? 1
            : -1;
        return sortColumn.order === "desc" ? order * -1 : order;
      });
    }

    const divisionsByPage = paginate(
      sortedDivisions,
      this.state.currentPage,
      this.state.pageSize
    );

    return { divisionsByPage, filteredDivisions, sortedDivisions };
  };

  render() {
    const {
      pageSize,
      currentPage,
      divisions,
      sortColumn,
      searchQuery,
      deleteOpen,
      deleteDialog,
      resetSuspensionsOpen,
      selectedDivisions,
      seasons,
      selectedSeasonId,
    } = this.state;
    const user = getCurrentUser();

    const { divisionsByPage, filteredDivisions, sortedDivisions } =
      this.getPageData();
    const downloadData = this.getDownloadData();

    return (
      <div>
        {divisions.length === 0 && user.orgID && (
          <CLink
            path={"/divisions?q=new"}
            button={true}
            buttonStyle="btn-lg btn-info btn-block"
          >
            Add New Division
          </CLink>
        )}
        {divisions.length !== 0 && (
          <div>
            <div className="row">
              <div className="col text-center">
                <NonFormSelect
                  name="_id"
                  label=""
                  options={seasons}
                  onChange={this.handleSelectSeason}
                  value={selectedSeasonId}
                  noDefaultOption={true}
                />
              </div>
              <div className="col text-center">
                <p className="header-p">
                  Showing {divisionsByPage.length} of {filteredDivisions.length}{" "}
                  divisions. There are {divisions.length} total divisions.
                </p>
              </div>
              <div className="col text-center">
                <DivisionsButtonLineup
                  user={user}
                  divisions={sortedDivisions}
                  multiSelectColumns={this.multiSelectColumns}
                  onMultiToggle={this.handleMultiToggle}
                  onResetSuspensions={() =>
                    this.toggleModal("resetSuspensionsOpen")
                  }
                  seasons={seasons}
                  onSeasonSelect={this.handleSelectSeason}
                  selectedSeasonId={selectedSeasonId}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <SearchBox
                  value={searchQuery}
                  onChange={this.handleSearch}
                  placeholder="Search by division name..."
                />
              </div>
              <div className="col-2">
                <ExcelDownload
                  data={downloadData}
                  columns={this.downloadColumns}
                  dataName="divisions"
                  tooltipDirection="top=left"
                />
              </div>
            </div>
            <DivisionsTable
              divisionsByPage={divisionsByPage}
              onDelete={this.setDeleteDialog}
              onSort={this.handleSort}
              sortColumn={sortColumn}
            />
            <Pagination
              itemsCount={filteredDivisions.length}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
              onPreviousPage={this.handlePreviousPage}
              onNextPage={this.handleNextPage}
            />
          </div>
        )}
        <hr />
        <QuickLinks
          fullBoard={true}
          org={this.props.org ? this.props.org : null}
        />
        <CustomConfirm
          dialog={deleteDialog}
          isOpen={deleteOpen}
          callback={this.handleDelete}
          close={this.toggleModal}
          id={"deleteOpen"}
          focused={true}
        />
        <CustomConfirm
          dialog={`Select the divisions to reset accumulated cards.
          The running yellow cards total for every player in this division will be reset. Current suspensions will remain intact.
          %This is only available for non archived divisions.`}
          callback={this.handleResetSuspensions}
          isOpen={resetSuspensionsOpen}
          close={this.toggleModal}
          id={"resetSuspensionsOpen"}
          focused={true}
          split="%"
        >
          <MatchesDivisionSelect
            divisions={sortedDivisions.filter(
              (d) => !d.archive || !d.archive.archived
            )}
            selectedDivisions={selectedDivisions}
            onSelectDivision={this.handleSelectDivision}
          />
        </CustomConfirm>
      </div>
    );
  }
}

export default Divisions;
