import React, { useState, useEffect } from "react";

import LeagueWebpage from "../leagueWebpage/leagueWebpage";
import SpectateHome from "../spectate/spectateHome";
import SetUpForm from "../leagueWebpage/setUpForm";
import OrgLogo from "../common/pageComponents/orgLogo";
import allowables from "../../utils/allowables";
import PageBottom from "../common/pageComponents/pageBottom";

import { getCurrentUser } from "../../services/userService";
import MiniHeader from "../common/pageComponents/miniHeader";
import SpectateLiveScorecards from "../spectate/spectateLiveScorecards";
import CustomAdUnit from "../ads/customAdUnit";
import { isManager } from "../../utils/permissionsUtil";

const LeagueHome = (props) => {
  const [page, setPage] = useState("home");

  useEffect(() => {
    if (props.location.search) {
      if (props.location.search === "?edit") setPage("edit");
      else if (props.location.search === "?home") setPage("home");
      else if (props.location.search.slice(1, 5) === "live") setPage("live");
      else setPage("stats");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetPage = (page) => {
    props.history.replace(`?${page}`);
    setPage(page);
  };

  const user = getCurrentUser();

  const bgColor = props.pageOrg?.webpage?.logoBgColor || "white";
  const bgTextColor = allowables.getTextColor(bgColor) || "black";
  const highlightColor = props.pageOrg?.webpage?.themeTextColor || "black";
  const textColor = allowables.getTextColor(highlightColor) || "white";
  const buttonStyle = (selected) => {
    if (selected) return { backgroundColor: highlightColor, color: textColor };
    return {};
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        color: bgTextColor,
        padding: 15,
        margin: -25,
      }}
      className="bg-light"
    >
      <div className="centered-small-input-area">
        <MiniHeader
          overrideStyle={{
            backgroundColor: highlightColor,
            color: textColor,
          }}
          onClick={() => handleSetPage("home")}
        >
          {props.pageOrg.logo && props.pageOrg.logo !== "false" ? (
            <OrgLogo
              _id={props.pageOrg.logo}
              name={props.pageOrg.name}
              width={200}
            />
          ) : (
            props.pageOrg.name
          )}
        </MiniHeader>
        <div
          className="form-divided-section"
          style={{
            borderColor: highlightColor,
            backgroundColor: bgColor,
            color: bgTextColor,
          }}
        >
          <div className="row">
            <div className="col">
              <button
                className={
                  "btn btn-block btn-sm btn-" +
                  (page === "home" ? "info font-weight-bold" : "light")
                }
                onClick={() => handleSetPage("home")}
                style={buttonStyle(page === "home")}
              >
                Home
              </button>
            </div>
            <div className="col">
              <button
                className={
                  "btn btn-block btn-sm btn-" +
                  (page === "stats" ? "info font-weight-bold" : "light")
                }
                onClick={() => handleSetPage("stats")}
                style={buttonStyle(page === "stats")}
              >
                Stats
              </button>
            </div>
          </div>
          {props.pageOrg?.misc?.liveScorecards && (
            <div style={{ marginTop: 10 }}>
              <button
                className={
                  "btn btn-block btn-sm btn-" +
                  (page === "live" ? "info font-weight-bold" : "light")
                }
                onClick={() => handleSetPage("live")}
                style={buttonStyle(page === "live")}
              >
                Live Scorecards
              </button>
            </div>
          )}
          {isManager(user) && (
            <div style={{ marginTop: 10 }}>
              <button
                className={
                  "btn btn-block btn-sm btn-" +
                  (page === "edit" ? "info font-weight-bold" : "light")
                }
                onClick={() => handleSetPage("edit")}
                style={buttonStyle(page === "edit")}
              >
                Edit
              </button>
            </div>
          )}
        </div>
        {page === "stats" && <SpectateHome {...props} />}
        {page === "live" && <SpectateLiveScorecards {...props} />}
        {(page === "home" || (!isManager(user) && page === "edit")) && (
          <LeagueWebpage {...props} />
        )}
        {isManager(user) && page === "edit" && <SetUpForm {...props} />}
        <PageBottom leaguePage={true} />
      </div>
      <CustomAdUnit org={props.pageOrg} user={null} page="spectate" />
    </div>
  );
};

export default LeagueHome;
