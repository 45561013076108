import http from "./httpService";

export async function saGetUsers(role, leagueID) {
  try {
    return await http.get(
      http.superAdminEndpoint + "/users/" + role + "/" + leagueID
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function saGetLeagues(sport) {
  try {
    return await http.get(http.superAdminEndpoint + "/leagues/" + sport);
  } catch (ex) {
    return ex.response;
  }
}

export async function saGetMessages(limit, offset) {
  try {
    return await http.get(
      http.messageEndpoint + `/super?limit=${limit}&offset=${offset}`
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function saGetMessage(id, limit, offset) {
  try {
    return await http.get(
      http.messageEndpoint + `/super/${id}?limit=${limit}&offset=${offset}`
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function saGetEmails(roles, sports, include) {
  try {
    return await http.get(
      http.superAdminEndpoint +
        `/emails?roles=${roles}&sports=${sports}${
          include ? "&include=true" : ""
        }`
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function saGetUserInfo(userID, populateAllFromProfile) {
  try {
    return await http.get(
      http.superAdminEndpoint +
        `/userinfo/${userID}?populate=${populateAllFromProfile}`
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function saGetUserInfoByEmail(email) {
  try {
    return await http.get(
      http.superAdminEndpoint + `/userinfobyemail/${email}`
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function saGetMemoryUsage() {
  try {
    return await http.get(http.superAdminEndpoint + "/memoryusage");
  } catch (ex) {
    return ex.response;
  }
}
