import React from "react";
import { IconContext } from "react-icons";
import {
  BiReset,
  BiChevronsDown,
  BiChevronsUp,
  BiChevronsRight,
  BiChevronsLeft,
  BiRefresh,
  BiUserCircle,
  BiLogOut,
  BiSolidNoEntry,
} from "react-icons/bi";
import { CgArrowsExchangeAlt, CgOrganisation } from "react-icons/cg";
import {
  FaClipboardList,
  FaInfoCircle,
  FaCheck,
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaCcJcb,
  FaCcDinersClub,
  FaStar,
  FaPlus,
  FaTable,
  FaVideo,
  FaSearch,
  FaMap,
  FaBan,
  FaSquare,
  FaFlag,
  FaDivide,
  FaLock,
  FaUnlock,
  FaRegCopyright,
  FaUndo,
  FaUsers,
  FaExternalLinkAlt,
  FaFileSignature,
  FaUserEdit,
  FaStripeS,
  FaArchive,
  FaUserCog,
  FaMinusCircle,
  FaHistory,
  FaRegCircle,
  FaUserMinus,
  FaUserPlus,
} from "react-icons/fa";
import {
  FaUsersRectangle,
  FaRankingStar,
  FaUsersGear,
  FaBaseballBatBall,
} from "react-icons/fa6";
import {
  GiSoccerBall,
  GiAmericanFootballBall,
  GiBasketballBall,
  GiGloves,
  GiSoccerKick,
  GiAmericanFootballHelmet,
  GiBaseballBat,
  GiBaseballGlove,
  GiDustCloud,
  GiThrowingBall,
  GiSoccerField,
  GiTripleNeedle,
  GiTennisRacket,
  GiBeachBall,
} from "react-icons/gi";
import {
  ImCheckboxChecked,
  ImCheckboxUnchecked,
  ImListNumbered,
  ImMakeGroup,
} from "react-icons/im";
import {
  IoMdSettings,
  IoMdSend,
  IoMdClose,
  IoMdBaseball,
} from "react-icons/io";
import {
  MdAddCircle,
  MdClear,
  MdLiveTv,
  MdEmail,
  MdPhone,
  MdScoreboard,
  MdDiscount,
  MdCreditCard,
} from "react-icons/md";
import { PiContactlessPaymentBold } from "react-icons/pi";
import {
  RiEdit2Line,
  RiCalendar2Line,
  RiVipDiamondLine,
  RiUserAddLine,
  RiUserSharedLine,
  RiDeleteBin4Line,
  RiDeleteBin6Line,
} from "react-icons/ri";
import { SiCheckmarx } from "react-icons/si";
import {
  TbWorldWww,
  TbColorFilter,
  TbArrowMoveUp,
  TbDragDrop,
  TbCircleNumber1,
  TbCircleLetterA,
  TbShirtSport,
  TbHelpCircle,
  TbTournament,
} from "react-icons/tb";
import { VscListTree } from "react-icons/vsc";

const icons = {
  add: MdAddCircle,
  amex: FaCcAmex,
  archive: FaArchive,
  arrowup: TbArrowMoveUp,
  ban: FaBan,
  baseball: IoMdBaseball,
  baseballbat: GiBaseballBat,
  baseballbatthick: FaBaseballBatBall,
  baseballglove: GiBaseballGlove,
  basketball: GiBasketballBall,
  bigcheck: SiCheckmarx,
  bracket: TbTournament,
  building: CgOrganisation,
  calendar: RiCalendar2Line,
  check: FaCheck,
  checkedbox: ImCheckboxChecked,
  chevrondown: BiChevronsDown,
  chevronup: BiChevronsUp,
  chevronright: BiChevronsRight,
  chevronleft: BiChevronsLeft,
  circle: FaRegCircle,
  clear: MdClear,
  clipboard: FaClipboardList,
  close: IoMdClose,
  color: TbColorFilter,
  contactlesspayment: PiContactlessPaymentBold,
  copyright: FaRegCopyright,
  delete: RiDeleteBin4Line,
  denied: BiSolidNoEntry,
  diamond: RiVipDiamondLine,
  discount: MdDiscount,
  discover: FaCcDiscover,
  divide: FaDivide,
  division: VscListTree,
  diners: FaCcDinersClub,
  drag: TbDragDrop,
  dust: GiDustCloud,
  edit: RiEdit2Line,
  email: MdEmail,
  externallink: FaExternalLinkAlt,
  flag: FaFlag,
  football: GiAmericanFootballBall,
  helmet: GiAmericanFootballHelmet,
  help: TbHelpCircle,
  history: FaHistory,
  info: FaInfoCircle,
  jcb: FaCcJcb,
  jersey: TbShirtSport,
  kick: GiSoccerKick,
  kickball: GiBeachBall,
  leaderboard: ImListNumbered,
  letter: TbCircleLetterA,
  live: MdLiveTv,
  lock: FaLock,
  logout: BiLogOut,
  map: FaMap,
  mastercard: FaCcMastercard,
  minus: FaMinusCircle,
  number: TbCircleNumber1,
  payment: MdCreditCard,
  phone: MdPhone,
  plus: FaPlus,
  purge: RiDeleteBin6Line,
  rank: FaRankingStar,
  recruit: RiUserAddLine,
  refresh: BiRefresh,
  remove: RiUserSharedLine,
  reset: BiReset,
  scoreboard: MdScoreboard,
  search: FaSearch,
  season: ImMakeGroup,
  send: IoMdSend,
  settings: IoMdSettings,
  simple: GiGloves,
  signature: FaFileSignature,
  square: FaSquare,
  soccer: GiSoccerBall,
  soccerField: GiSoccerField,
  star: FaStar,
  stripe: FaStripeS,
  switch: CgArrowsExchangeAlt,
  table: FaTable,
  team: FaUsersRectangle,
  tennis: GiTennisRacket,
  throw: GiThrowingBall,
  uncheckedbox: ImCheckboxUnchecked,
  undo: FaUndo,
  unlock: FaUnlock,
  userDelete: FaUserMinus,
  userEdit: FaUserEdit,
  userImages: BiUserCircle,
  users: FaUsers,
  userAdd: FaUserPlus,
  userSettings: FaUserCog,
  manageusers: FaUsersGear,
  vaccine: GiTripleNeedle,
  video: FaVideo,
  visa: FaCcVisa,
  web: TbWorldWww,
};

const IconRender = ({ name, className, style }) => {
  const Icon = icons[name];
  if (!Icon) return null;

  if (className)
    return (
      <IconContext.Provider value={{ className, style }}>
        <Icon />
      </IconContext.Provider>
    );

  return <Icon />;
};

export default IconRender;
