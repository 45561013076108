import React from "react";
import Joi from "joi-browser";
import HeaderContext from "../../context/headerContext";
import Form from "../common/form/form";
import BasicModal from "../common/pageComponents/basicModal";
import { popupStyle } from "../../utils/styleUtil";
import {
  addLeagueToUmbrella,
  deleteUmbrella,
  editUmbrella,
  getUmbrella,
  removeLeagueFromUmbrella,
} from "../../services/umbrellaService";
import toast from "../../utils/toast";
import MiniHeader from "../common/pageComponents/miniHeader";
import WarningHeader from "../common/pageComponents/warningHeader";
import CustomConfirm from "../common/customs/customConfirm";
import UmbrellaInfo from "./umbrellaInfo";
import { getCurrentUser } from "../../services/userService";
import { isOwner } from "../../utils/permissionsUtil";

class UmbrellaForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: { name: "" },
    errors: {},
    baseState: {},
    exists: false,
    editMode: false,
    formOpen: false,
    deleteOpen: false,
  };

  async componentDidMount() {
    const res = await getUmbrella();
    this.setState({
      data: this.mapToViewModel(res?.status === 200 ? res.data : {}),
      baseState: this.mapToViewModel(res?.status === 200 ? res.data : {}),
      exists: res?.status === 200,
      editMode: false,
    });
  }

  schema = {
    name: Joi.string().required().max(50).label("Organization Name"),
    webAddress: Joi.string().required().max(50).label("Website Address"),
    header: Joi.string().optional().allow("").max(50).label("Header Text"),
    text: Joi.string().optional().allow("").max(500).label("Paragraph Text"),
    backgroundColor: Joi.string()
      .allow("")
      .max(50)
      .label("Page Background Color"),
    color: Joi.string().allow("").max(50).label("Page Text Color"),
  };

  mapToViewModel(umbrella) {
    return {
      name: umbrella.name || "",
      webAddress: umbrella.webAddress || "",
      header: umbrella.webpage?.header || "",
      text: umbrella.webpage?.text || "",
      backgroundColor: umbrella.webpage?.backgroundColor || "#ffffff",
      color: umbrella.webpage?.color || "#000000",
    };
  }

  handleDeleteOrganization = async () => {
    this.context.setLoading(false);
    const res = await deleteUmbrella();
    if (res.status === 200) {
      toast.success("Organization Deleted");
      await this.componentDidMount();
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  handleRemoveLeagueFromUmbrella = async () => {
    this.context.setLoading(true);
    const res = await removeLeagueFromUmbrella();
    if (res.status === 200) {
      toast.success("League Removed from Organization");
      await this.context.refreshOrg();
      await this.componentDidMount();
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  doSubmit = async () => {
    this.context.setLoading(true);
    let data = { ...this.state.data };

    let webpage = {};
    ["header", "text", "backgroundColor", "color"].forEach((webpageElem) => {
      webpage[webpageElem] = data[webpageElem];
      delete data[webpageElem];
    });

    data.webpage = webpage;

    const res = await (this.state.editMode
      ? editUmbrella(data)
      : addLeagueToUmbrella(data));
    if (res.status === 200) {
      toast.success(
        this.state.editMode
          ? "Organization Updated"
          : this.state.exists
          ? "League added to Organization"
          : "Organization Created"
      );
      await this.context.refreshOrg();
      await this.componentDidMount();
    } else {
      toast.error(res.data);
    }
    this.context.setLoading(false);
  };

  render() {
    const { editMode, exists, formOpen, deleteOpen, baseState } = this.state;
    const { org } = this.props;
    const isUnderUmbrella = org && org?.umbrellaID;
    const header = (exists ? "Manage" : "Create") + " My Organization";

    if (!isOwner(getCurrentUser())) return null;

    return (
      <div>
        <button
          className="btn btn-info btn-sm btn-block"
          onClick={() => this.setState({ formOpen: !formOpen })}
        >
          {header}
        </button>
        <BasicModal
          isOpen={formOpen}
          onClose={() => this.setState({ formOpen: false })}
          style={{
            content: {
              ...popupStyle.content,
              minWidth: "60%",
            },
          }}
        >
          {exists && !isUnderUmbrella ? (
            <WarningHeader>
              This League is not currently part of your Organization
            </WarningHeader>
          ) : null}
          {!exists || editMode ? (
            <div>
              {this.renderWholeForm(
                "Manage Organization",
                null,
                null,
                <div>
                  {this.renderInput("name", "Organization Name")}
                  {this.renderInput(
                    "webAddress",
                    "Website Address",
                    null,
                    null,
                    null,
                    null,
                    null,
                    {
                      header: "Website Address",
                      text: `The full web address (including https://) to your website`,
                      direction: "right",
                      className: "icon-mobile",
                    }
                  )}
                  {this.renderInput("header", "Header Text")}
                  {this.renderTextArea("text", "Body Text")}
                  <div className="row">
                    <div className="col">
                      {this.renderColorPicker(
                        "backgroundColor",
                        "Background Color"
                      )}
                    </div>
                    <div className="col">
                      {this.renderColorPicker("color", "Text Color")}
                    </div>
                  </div>
                </div>,
                false,
                {
                  overrideHeader: header,
                  inputDivClass: "form-divided-section",
                }
              )}
              <button
                className="btn btn-sm btn-dark"
                onClick={() => this.setState({ editMode: false })}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div>
              <MiniHeader>{baseState.name}</MiniHeader>
              <div className="form-divided-section">
                <UmbrellaInfo info={baseState} />
              </div>
              <div className="row">
                <div className="col">
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => this.setState({ editMode: true })}
                  >
                    Edit Org
                  </button>
                </div>
                <div className="col text-right">
                  <button
                    className="btn btn-dark btn-sm"
                    onClick={() => this.setState({ deleteOpen: true })}
                  >
                    Delete Org
                  </button>
                </div>
              </div>
              <div className="pop-box">
                <button
                  className={
                    "btn btn-block btn-sm btn-" +
                    (isUnderUmbrella ? "dark" : "info")
                  }
                  onClick={
                    isUnderUmbrella
                      ? this.handleRemoveLeagueFromUmbrella
                      : this.doSubmit
                  }
                >
                  {isUnderUmbrella ? "Remove" : "Add"} this League{" "}
                  {isUnderUmbrella ? "from" : "to"} Organization
                </button>
              </div>
            </div>
          )}
          <CustomConfirm
            dialog="Delete Organization%Are you sure?"
            callback={this.handleDeleteOrganization}
            isOpen={deleteOpen}
            close={() => this.setState({ deleteOpen: false })}
            yesNo={true}
            split="%"
          />
        </BasicModal>
      </div>
    );
  }
}

export default UmbrellaForm;
