import React, { useState } from "react";

import DiamondSelectedPlayer from "./diamondSelectedPlayer";
import DiamondPitcherPopup from "./diamondPitcherPopup";
import DiamondPopupEvent from "./diamondPopupEvent";
import CustomAlert from "../../common/customs/customAlert";
import CustomConfirm from "../../common/customs/customConfirm";
import DiamondInningSegment from "./diamondInningSegment";
import { atBatName } from "../../../utils/diamondFunctions";

const DiamondTeamSegment = ({
  match,
  players,
  teamOrder,
  pitchers,
  setPitchers,
  chronoEventsNames,
  outs,
  onEvent,
  org,
}) => {
  const [openPitcher, setOpenPitcher] = useState(null);
  const [openAtBat, setOpenAtBat] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [pitcherToOpen, setPitcherToOpen] = useState("home");
  const [pitcherDialog, setPitcherDialog] = useState("");
  const [dialog, setDialog] = useState("");
  const [selectedAdd, setSelectedAdd] = useState({ type: "", team: "" });
  const [confirmOpen, setConfirmOpen] = useState(false);

  const togglePitcherModal = (id) => {
    setOpenPitcher(null);
  };

  const getOppTeam = (team) => {
    if (team === "home") return "away";
    return "home";
  };

  const checkForPitcher = (team, add) => {
    if (!pitchers[getOppTeam(team)]) {
      setPitcherToOpen(getOppTeam(team));
      setPitcherDialog(
        `Please first select a pitcher for ${
          match[getOppTeam(team) + "TeamName"]
        }`
      );
      setAlertOpen(true);
    } else if (add) {
      setSelectedAdd({ type: add, team });
      setConfirmOpen(true);
    } else {
      mapPlayerEvents(team);
      setOpenAtBat(team);
    }
  };

  const mapPlayerEvents = (team) => {
    if (chronoEventsNames?.length === 0) return;
    players[team].forEach((p) => {
      let atBats = 0;
      chronoEventsNames.forEach((e) => {
        if ((p.profileID?._id || p._id) === e._id) {
          atBats++;
        }
      });
      p.matchEvents = { atBats };
    });
  };

  const handleEvent = (batter, event) => {
    const team = batter.teamID === match.homeTeamID ? "home" : "away";
    onEvent(batter, event, pitchers[getOppTeam(team)], team);
  };

  const handleAdd = () => {
    if (!selectedAdd || !selectedAdd.type || !selectedAdd.team) return;
    onEvent(
      {
        _id: "unk",
        name: selectedAdd.type === "out" ? "Out" : "stolen base",
        teamID: match[selectedAdd.team + "TeamID"],
        teamName: match[selectedAdd.team + "TeamName"],
      },
      {
        result: selectedAdd.type === "out" ? "out" : "stolen base",
        inDepth: selectedAdd.type === "out" ? "misc out" : "stole home",
        rbis: selectedAdd.type === "out" ? 0 : 1,
      },
      pitchers[getOppTeam(selectedAdd.team)],
      selectedAdd.team
    );
  };

  return (
    <React.Fragment>
      <br />
      <div className="row">
        {teamOrder.map((team) => {
          return (
            <div className="col" key={team}>
              <button
                className="btn btn-info btn-block btn-lg"
                onClick={() => checkForPitcher(team)}
              >
                {atBatName(org).action}
              </button>
              <br />
              {pitchers[team] ? (
                <DiamondSelectedPlayer
                  player={pitchers[team]}
                  header="Pitcher"
                  onClick={() => setOpenPitcher(team)}
                />
              ) : (
                <button
                  className="btn btn-light btn-block"
                  onClick={() => setOpenPitcher(team)}
                >
                  Pitcher
                </button>
              )}
              <br />
              <div className="row">
                <div className="col">
                  <button
                    className="btn btn-block btn-dark btn-sm"
                    onClick={() => {
                      setDialog(`Add out for ${match[team + "TeamName"]}?%
                    This button should only be used for outs that happen outside the context of ${
                      atBatName(org).article
                    } ${atBatName(
                        org
                      ).action.toLowerCase()}, for example to add an out for a double play.`);
                      checkForPitcher(team, "out");
                    }}
                    aria-label="non-at-bat-out"
                  >
                    Out
                  </button>
                </div>
                <div className="col">
                  <button
                    className="btn btn-block btn-info btn-sm"
                    onClick={() => {
                      setDialog(`Add run for ${match[team + "TeamName"]}?%
                    This button should only be used for runs scored outside the context of ${
                      atBatName(org).article
                    } ${atBatName(
                        org
                      ).action.toLowerCase()}, i.e. if a player steals home.`);
                      checkForPitcher(team, "run");
                    }}
                    aria-label="non-at-bat-run"
                  >
                    Run
                  </button>
                </div>
              </div>
              <DiamondPitcherPopup
                players={players[team]}
                toggleModal={togglePitcherModal}
                id={team}
                isOpen={openPitcher === team}
                team={team}
                teamName={match[team + "TeamName"]}
                onSelect={setPitchers}
              />
              <DiamondPopupEvent
                isOpen={openAtBat === team}
                toggleModal={setOpenAtBat}
                match={match}
                team={team}
                pitcher={pitchers[getOppTeam(team)]}
                players={players[team]}
                onFinalizeEvent={handleEvent}
                onChangePitcher={() => setOpenPitcher(getOppTeam(team))}
                org={org}
              />
            </div>
          );
        })}
      </div>
      <br />
      <DiamondInningSegment outs={outs} />
      <CustomConfirm
        dialog={dialog}
        isOpen={confirmOpen}
        callback={handleAdd}
        close={setConfirmOpen}
        yesNo={true}
        split="%"
      />
      <CustomAlert
        dialog={pitcherDialog}
        isOpen={alertOpen}
        close={setAlertOpen}
        callback={() => setOpenPitcher(pitcherToOpen)}
      />
    </React.Fragment>
  );
};

export default DiamondTeamSegment;
