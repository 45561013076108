import React, { Component } from "react";
import Table from "../common/table/table";
import { getCurrentUser } from "../../services/userService";
import Pagination from "../common/dataSorting/pagination";
import { paginate } from "../../utils/paginate";
import orderBy from "lodash/orderBy";
import SearchBox from "../common/dataSorting/searchBox";
import MailingList from "../common/dataSorting/mailingList";
import allowables from "../../utils/allowables";
import EventImage from "./../assignedMatch/eventImage";
import ExcelDownload from "../common/pageComponents/excelDownload";
import HeaderContext from "../../context/headerContext";
import WarningHeader from "../common/pageComponents/warningHeader";
import CheckboxGroup from "../common/dataSorting/checkboxGroup";
import TooltipButton from "../common/tooltip/tooltipButton";
import IconRender from "../common/icons/iconRender";
import { isManager, isOwner } from "../../utils/permissionsUtil";

const defaultRoles = {
  admin: true,
  ref: true,
  captain: true,
  player: true,
};
class OrgTable extends Component {
  static contextType = HeaderContext;
  state = {
    sortColumn: { path: "name", order: "asc" },
    searchQuery: "",
    selectedRoles: defaultRoles,
    currentPage: 1,
    pageSize: allowables.pageSize,
  };

  componentDidMount() {}

  removeButton = {
    key: "delete",
    content: (user) =>
      !isOwner(user) && getCurrentUser()?._id !== user._id ? (
        <TooltipButton
          onClick={() => this.props.onDelete(user)}
          buttonClass="btn btn-dark btn-sm"
          tooltipDirection="left"
          tooltipText={"Remove User from League"}
          data-testid="remove-user"
        >
          <IconRender name="userDelete" />
        </TooltipButton>
      ) : null,
  };

  updateRoleButton = {
    key: "roleUpdate",
    content: (user) =>
      isOwner(getCurrentUser()) &&
      getCurrentUser()?._id !== user._id &&
      (user.role?.toLowerCase().includes("admin") ||
        user.role?.toLowerCase().includes("referee")) ? (
        <TooltipButton
          onClick={() => this.props.onUpdateUserRole(user)}
          buttonClass="btn btn-secondary btn-sm"
          tooltipDirection="left"
          tooltipText="Update user role"
          data-testid="update-role"
        >
          <IconRender name="userEdit" />
        </TooltipButton>
      ) : null,
  };

  constructor(props) {
    super(props);
    const user = getCurrentUser();
    if (isManager(user))
      this.columns.push(this.updateRoleButton, this.removeButton);
  }

  columns = [
    { path: "hasAvatar", label: "", content: (u) => <EventImage player={u} /> },
    {
      path: "name",
      label: "Name",
      content: (u) => allowables.splitName(u.name),
    },
    {
      path: "email",
      label: "Email",
      content: (u) => <a href={`mailto:${u.email}`}>{u.email}</a>,
    },
    { path: "teamName", label: "Team Name" },
    {
      path: "role",
      label: "Role",
      content: (u) => allowables.translateRole(u?.role, u?.sport),
    },
  ];

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePreviousPage = () => {
    const currentPage = this.state.currentPage;
    this.setState({ currentPage: currentPage - 1 });
  };

  handleNextPage = () => {
    const currentPage = this.state.currentPage;
    this.setState({ currentPage: currentPage + 1 });
  };

  handleSort = (sortColumn) => {
    if (sortColumn.path) {
      this.setState({ sortColumn });
    }
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  getPageData = () => {
    const { sortColumn, searchQuery, selectedRoles } = this.state;
    const { users } = this.props;
    const sortedUsers = orderBy(users, [sortColumn.path], [sortColumn.order]);
    let filteredUsers = sortedUsers;
    if (searchQuery)
      filteredUsers = sortedUsers.filter((u) =>
        u.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

    filteredUsers = filteredUsers.filter((u) => {
      const role = u.role.toLowerCase();
      return (
        (selectedRoles.admin && role.includes("admin")) ||
        (selectedRoles.ref &&
          (role.includes("referee") || role.includes("umpire"))) ||
        (selectedRoles.captain && role.includes("captain")) ||
        (selectedRoles.player && role.includes("player"))
      );
    });

    const usersByPage = paginate(
      filteredUsers,
      this.state.currentPage,
      this.state.pageSize
    );

    return { usersByPage, filteredUsers };
  };

  render() {
    const { sortColumn, searchQuery, pageSize, currentPage, selectedRoles } =
      this.state;

    const { users } = this.props;
    const { usersByPage, filteredUsers } = this.getPageData();
    const downloadColumns =
      users && users.length > 0
        ? Object.keys(users[0]).filter(
            (c) => !["profileID", "passwordResetToken"].includes(c)
          )
        : [];

    if (!isManager(getCurrentUser())) return null;

    return (
      <div>
        {users.length === 0 && (
          <div className="text-center">
            {!this.props.loaded ? (
              <p>
                No users loaded yet. User table is not gathered automatically to
                speed up load times.
              </p>
            ) : (
              <WarningHeader>
                There are currently no users in this league.
              </WarningHeader>
            )}
          </div>
        )}
        <button
          className="btn btn-block btn-info"
          onClick={() => {
            this.setState({
              selectedRoles: defaultRoles,
              searchQuery: "",
            });
            this.props.loadUsers();
          }}
        >
          {this.props.loaded ? "Reload" : "Load"} User Table
        </button>
        <br />

        {users.length !== 0 && (
          <div>
            <CheckboxGroup
              header="Filter Data"
              onChange={(id) => {
                let currentRoles = { ...selectedRoles };
                currentRoles[id] = !currentRoles[id];
                this.setState({ selectedRoles: currentRoles });
              }}
              containerClassName="pop-box"
              colCount={2}
              checkboxes={[
                {
                  id: "admin",
                  value: selectedRoles.admin,
                  label: "Admin",
                },
                {
                  id: "ref",
                  value: selectedRoles.ref,
                  label: `${allowables.refOrUmp(this.props.sport)}`,
                },
                {
                  id: "captain",
                  value: selectedRoles.captain,
                  label: "Team Captain",
                },
                {
                  id: "player",
                  value: selectedRoles.player,
                  label: "Player",
                },
              ]}
            />
            <div className="row">
              <div className="col text-center">
                <p>
                  There are {users.length} active users. Showing{" "}
                  {currentPage === 1 &&
                  searchQuery === "" &&
                  filteredUsers.length > pageSize
                    ? "the first "
                    : ""}{" "}
                  {usersByPage.length}.
                </p>
              </div>
              <div className="col text-right">
                <MailingList
                  users={filteredUsers}
                  buttonTitle="Create Mailing List"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <SearchBox
                  value={searchQuery}
                  onChange={this.handleSearch}
                  placeholder={"Search by name..."}
                />
              </div>
              <div className="col-2">
                <ExcelDownload
                  data={users}
                  columns={downloadColumns}
                  dataName="users"
                  tooltipDirection="top-left"
                />
              </div>
            </div>
            <Table
              columns={this.columns}
              data={usersByPage}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              keyProperty={"_id"}
              tableClass="table-custom"
              headerClass="thead-custom"
              bodyClass="tbody-custom"
              highlightOnHover={true}
            />
            <Pagination
              itemsCount={filteredUsers.length}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
              onPreviousPage={this.handlePreviousPage}
              onNextPage={this.handleNextPage}
            />
          </div>
        )}
      </div>
    );
  }
}

export default OrgTable;
