import React, { useState, useEffect, useContext } from "react";
import toast from "../../utils/toast";
import orderBy from "lodash/orderBy";

import { getUsersForOrg } from "../../services/userService";
import SearchBox from "../common/dataSorting/searchBox";
import UserInformationTable from "./userInformationTable";
import MailingList from "../common/dataSorting/mailingList";
import HeaderContext from "../../context/headerContext";
import allowables from "../../utils/allowables";

const UserInformationView = () => {
  const { setLoading, setProgress } = useContext(HeaderContext);
  const [sortColumn, setSortColumn] = useState({ path: "name", order: "asc" });
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]);

  const loadData = async () => {
    setLoading(true);
    setProgress(1);
    const res = await getUsersForOrg({
      callback: (p) => setProgress(p),
      bar: 0,
    });
    if (res.status === 200) {
      setUsers(
        res.data.map((u) => ({
          ...u,
          imageUploadedBy: u.profileID?.hasAvatar
            ? u.profileID.hasAvatar?.toLowerCase().includes("blocked")
              ? u.profileID.hasAvatar
              : "↑ User"
            : u.playerID?.image
            ? "↑ " + u.playerID.image.split("-").at(-1).split("_").join(" ")
            : "",
          shirtSize: u.playerID?.personalInformation?.shirtSize || "",
          genderIdentity: u.playerID?.personalInformation?.genderIdentity || "",
          pronouns: u.playerID?.personalInformation?.pronouns || "",
        }))
      );
    } else toast.error(res.data);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (col) => {
    let newSort = { path: col.path, order: "asc" };
    if (col.path === sortColumn.path)
      newSort.order = sortColumn.order === "asc" ? "desc" : "asc";
    setSortColumn(newSort);
  };

  const getPageData = () => {
    let data = [...users];

    if (query) {
      const q = query.toLowerCase();
      data = data.filter(
        (d) =>
          allowables.splitName(d.name).toLowerCase().includes(q) ||
          d.teamName?.toLowerCase().includes(q)
      );
    }
    data = orderBy(data, [sortColumn.path], [sortColumn.order]);

    return data;
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <SearchBox
            value={query}
            onChange={setQuery}
            placeholder="Search by player or team name..."
          />
        </div>
        <div className="col-2">
          <MailingList
            users={getPageData()}
            buttonTitle="Create Mailing List"
            margin="20px"
          />
        </div>
      </div>
      <UserInformationTable
        users={getPageData()}
        onSort={handleSort}
        sortColumn={sortColumn}
      />
    </div>
  );
};

export default UserInformationView;
