import React from "react";
import { MdMoneyOff, MdCheckBox } from "react-icons/md";

import Table from "../../common/table/table";
import SearchBox from "../../common/dataSorting/searchBox";
import MailingList from "../../common/dataSorting/mailingList";
import { makeCustomTableButton, combineButtons } from "../../../utils/buttons";
import allowables from "../../../utils/allowables";
import { getPiLink, isPaid } from "../../../utils/paymentsUtil";
import IconRender from "../../common/icons/iconRender";

const PaymentTrackingTable = ({
  org,
  data,
  sortColumn,
  onSort,
  onSearch,
  searchQuery,
  selectedType,
  onMarkPaid,
  onRefund,
  isArchive,
}) => {
  const columns = [
    {
      path: "name",
      label: org.stripe.whoPays === "Team" ? "Captain" : "Player",
      content: (p) =>
        allowables.splitName(
          org.stripe.whoPays === "Team"
            ? p.teamCaptainID?.name || p.name
            : p.name
        ),
    },
    {
      path: "email",
      label: "Email",
      content: (p) => (
        <a
          href={`mailto:${
            p.teamCaptainID?.email || p.userID?.email || p.email
          }`}
        >
          {p.teamCaptainID?.email || p.userID?.email || p.email}
        </a>
      ),
    },
    {
      path: "teamName",
      label: "Team Name",
      content: (p) => (org.stripe.whoPays === "Team" ? p.name : p.teamName),
    },
    {
      path: "seasonName",
      label: "Season Name",
      content: (p) =>
        org.stripe.whoPays === "Team"
          ? p.divisionID?.seasonID?.name
          : p.teamID?.divisionID?.seasonID?.name,
    },
    {
      path: "payment.amountPaid",
      label: "Amount Paid",
      content: (d) =>
        `${
          d.payment && d.payment.amountPaid
            ? d.payment.amountPaid.toFixed(2)
            : (0).toFixed(2)
        }`,
    },
    {
      path: "payment.discountUsed.name",
      label: "Discount Info",
      content: (d) =>
        isPaid(d.payment) && d.payment?.discountUsed ? (
          <span>
            <small>{d.payment.discountUsed.name}</small> -{" "}
            <b>{(d.payment.discountUsed.amount || 0).toFixed(2)}</b>
          </span>
        ) : (
          ""
        ),
    },
    {
      path: "payment.external",
      label: "External Payment",
      content: (d) => (d.payment?.external ? "Yes" : "No"),
    },
    {
      path: "payment.datePaid",
      label: "Date Paid",
      content: (d) =>
        d.payment && d.payment.datePaid
          ? allowables.formDate(d.payment.datePaid, true)
          : "",
    },
    {
      path: "payment.paymentIntent",
      label: "Link",
      content: (d) =>
        isPaid(d) && d.payment?.paymentIntent && org?.stripe?.accountID ? (
          <a
            href={getPiLink(d.payment.paymentIntent, org)}
            target="_blank"
            rel="noopener noreferrer"
          >
            View in Stripe <IconRender name="externallink" />
          </a>
        ) : (
          ""
        ),
    },
  ];

  if (isArchive) {
    columns.splice(3, 0, { path: "paymentType", label: "Payment Type" });
  }

  let buttons = [];
  buttons.push(
    makeCustomTableButton(
      <MdCheckBox />,
      "Mark As Paid",
      "info btn-sm",
      "markPaid",
      onMarkPaid,
      "top-left",
      "",
      function (x) {
        return allowables.splitName(x.name);
      }
    )
  );
  buttons.push(
    makeCustomTableButton(
      <MdMoneyOff />,
      "Mark As Unpaid",
      "dark btn-sm",
      "refund",
      onRefund,
      "top-left",
      "",
      function (x) {
        return allowables.splitName(x.name);
      }
    )
  );
  if (!isArchive) columns.push(combineButtons(buttons));

  const getUsers = () => {
    if (org.stripe.whoPays === "Player")
      return data.filter((p) => p.userID).map((p) => p.userID);
    else if (org.stripe.whoPays === "Team")
      return data.filter((t) => t.teamCaptainID).map((t) => t.teamCaptainID);
    else return [];
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <SearchBox
            onChange={onSearch}
            placeholder={`Search by ${org.stripe.whoPays.toLowerCase()} name...`}
            value={searchQuery}
          />
        </div>
        <div className="col-2">
          <MailingList
            users={getUsers()}
            buttonTitle="Create Mailing List"
            secondaryTitle={
              selectedType.charAt(0).toUpperCase() + selectedType.slice(1)
            }
            margin="20px"
          />
        </div>
      </div>

      <Table
        columns={columns}
        data={data}
        sortColumn={sortColumn}
        onSort={onSort}
        keyProperty={"_id"}
        tableClass="table-custom"
        headerClass="thead-custom"
        bodyClass="tbody-custom"
      />
    </React.Fragment>
  );
};

export default PaymentTrackingTable;
