import React, { Component } from "react";

import Matches from "./matches";
import MatchForm from "./matchForm";
import { getFields } from "../../services/fieldService";
import { getDivisions } from "../../services/divisionService";
import { getTeams } from "../../services/teamService";
import { getMatch, saveMatch, deleteMatch } from "../../services/matchService";
import { getReferees, getCurrentUser } from "../../services/userService";
import MatchInfoView from "./matchInfoView";
import HeaderContext from "../../context/headerContext";

class MatchRedirect extends Component {
  static contextType = HeaderContext;
  state = {
    loading: true,
    comp: Matches,
    matchID: "",
  };

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) this.setup();
  }

  setup = () => {
    let comp = Matches;
    const query = this.props.location.search.slice(1).split("&");
    let matchID = query.find((q) => q.split("=")[0] === "q");
    matchID = matchID && matchID.split("=")[1];
    if (matchID) {
      this.context.setLoading(true);
      if (getCurrentUser().role.includes("admin")) comp = MatchForm;
      else comp = MatchInfoView;
    }
    this.setState({ loading: false, matchID, comp });
  };

  render() {
    return this.state.loading ? null : (
      <this.state.comp
        {...this.props}
        matchID={this.state.matchID}
        getFields={getFields}
        getCurrentUser={getCurrentUser}
        getReferees={getReferees}
        getMatch={getMatch}
        saveMatch={saveMatch}
        deleteMatch={deleteMatch}
        getTeams={getTeams}
        getDivisions={getDivisions}
      />
    );
  }
}

export default MatchRedirect;
