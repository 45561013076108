import React, { useState } from "react";
import { FaStar, FaClipboardList } from "react-icons/fa";

import MatchHeader from "../assignedMatch/matchHeader";
import ReviewInfoSegment from "../matchReview/reviewInfoSegment";
import HorizontalTabs from "../common/dataSorting/horizontalTabs";
import EventsTab from "../assignedMatch/eventsTab";
import allowables from "../../utils/allowables";
import {
  createFootballEvent,
  createSoccerEvent,
  mapTennisScore,
} from "ultimatescoreboard-shared";
import SimpleScoringInfo from "../assignedMatch/simple/simpleScoringInfo";
import EventEditModal from "../assignedMatch/eventEditModal";
import { getCurrentUser } from "../../services/userService";
import toast from "../../utils/toast";

const LiveScorecard = ({
  org,
  matchData,
  liveData,
  teamOrder,
  isScoreboard,
  spectate,
  teams,
  players,
  onEditPlayerEvent,
}) => {
  const [eventEditOpen, setEventEditOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [selectedTab, setSelectedTab] = useState("info");
  const tabs = [{ name: "info", icon: FaStar }];
  if (!matchData || !liveData) return null;

  let complexScore;
  const translatedSportName = allowables.translateSportName(org);
  if (translatedSportName === "Tennis") {
    complexScore = mapTennisScore(
      matchData?.divisionID?.settings?.tennisOptions,
      liveData?.events || matchData.chronoEventsNames
    );
  }

  if (!matchData.sport.toLowerCase().includes("simple")) {
    tabs.push({ name: "events", icon: FaClipboardList });
  } else {
    if (translatedSportName === "Tennis") {
      tabs.push({ name: "scoring", icon: FaClipboardList });
    }
  }

  const canEditEvents = () => {
    const user = getCurrentUser();
    return user?.role?.includes("captain");
  };

  const handleEdit = async (originalEvent, player, newEvent, player2) => {
    const user = getCurrentUser();
    let event;
    switch (user?.sport.toLowerCase()) {
      case "football":
        event = createFootballEvent(player, originalEvent, player2);
        break;
      case "soccer":
        event = createSoccerEvent(player, originalEvent.eventType, player2);
        break;
      default:
    }

    if (!event)
      return toast.error("Your sport does not support player event editing");
    onEditPlayerEvent({ ...event[0], eventID: originalEvent.eventID });
  };

  return (
    <div>
      {!isScoreboard && tabs.length > 1 ? (
        <HorizontalTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onSelectTab={(tab) => setSelectedTab(tab.name)}
        />
      ) : null}
      <MatchHeader
        org={org}
        match={matchData}
        teamOrder={teamOrder}
        goals={{ home: liveData.homeTeamScore, away: liveData.awayTeamScore }}
        penalties={{ home: liveData.homeTeamPKs, away: liveData.awayTeamPKs }}
        spectate={spectate}
        complexScore={complexScore}
        division={matchData.divisionID}
        penaltyVisualization={{
          home: liveData.homeTeamPKVis,
          away: liveData.awayTeamPKVis,
        }}
      />
      {translatedSportName !== "Tennis" ? (
        <div className="pop-box">
          {selectedTab === "info" ? (
            <ReviewInfoSegment
              data={matchData}
              teams={teamOrder}
              liveEvents={liveData.events}
              alreadyPopped={true}
            />
          ) : selectedTab === "events" ? (
            <EventsTab
              chronoEventsNames={liveData.events}
              sport={matchData.sport}
              match={matchData}
              spectate={!canEditEvents() || !org?.misc?.userReportedStats}
              onEdit={(event) => {
                setEventEditOpen(true);
                setEditingEvent(event);
              }}
            />
          ) : null}
        </div>
      ) : selectedTab === "scoring" ? (
        <div className="pop-box">
          <SimpleScoringInfo org={org} match={matchData} />
        </div>
      ) : null}
      <EventEditModal
        originalEvent={editingEvent}
        onSave={handleEdit}
        id="editingEventOpen"
        isOpen={eventEditOpen}
        onClose={() => setEventEditOpen(false)}
        teams={teams}
        players={players}
        sport={matchData.sport.toLowerCase()}
        allEvents={liveData.events}
        playersOnly={true}
      />
    </div>
  );
};

export default LiveScorecard;
