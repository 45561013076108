import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import NotFound from "./notFound";
import LoginForm from "./loginLogoutRegister/loginForm";
import CodeEntryForm from "./loginLogoutRegister/codeEntryForm";
import ProtectedRoute from "./common/routing/protectedRoute";
import Profile from "./profile/profile";
import Reports from "./reports/reports";
import CreateHome from "./create/createHome";
import SubscriptionHome from "./payment/subscriptions/subscriptionHome";
import HelpPageMain from "./instructions/helpPageMain";
import DivisionRedirect from "./divisions/divisionRedirect";
import SeasonsRedirect from "./seasons/seasonsRedirect";
import TeamRedirect from "./teams/teamRedirect";
import PlayerRedirect from "./players/playerRedirect";
import MatchRedirect from "./matches/matchRedirect";
import MatchReviewRedirect from "./matchReview/matchReviewRedirect";
import AssignedMatchRedirect from "./assignedMatch/assignedMatchRedirect";
import CodeofConductInfo from "./codeOfConduct/codeOfConductInfo";
import VaccineMain from "./vaccine/vaccineMain";
import LiveScorecardsMain from "./scorecards/liveScorecardsMain";
import PaymentsRedirect from "./payment/playerPayments/paymentsRedirect";
import OffCyclePayment from "./payment/offCyclePayment/offCyclePayment";
import HomeRedirect from "./homepage/homeRedirect";
import SuperAdminHome from "./superadmin/superAdminHome";
import OrgRedirect from "./organization/orgRedirect";
import FreeAgentRegistrationPage from "./loginLogoutRegister/freeAgentRegistrationPage";
import UserInformationView from "./userInformation/userInformationView";
import CustomFlagsView from "./customFlags/customFlagsView";
import PrepaymentSuccess from "./payment/playerPayments/prepaymentSuccess";
import ArchiveHome from "./archive/archiveHome";
import RegisterForm from "./loginLogoutRegister/registerForm";
import FinePrint from "./contactPolicies/finePrint";
import Unsubscribe from "./unsubscribe";
import DeepLinkRedirect from "./deepLinkRedirect";
import ScoreboardsHome from "./scoreboards/scoreboardsHome";
import FieldsRedirect from "./fields/fieldsRedirect";
import InvitationRegistrationPage from "./loginLogoutRegister/invitationRegistrationPage";
import MessageCenter from "./messages/messageCenter";
import Tactics from "./teamTactics/tactics";
import QuickStartForm from "./organization/quickStartForm";
import Discounts from "./discounts/discounts";
import RefereesRedirect from "./referees/refereesRedirect";
import ContactUsForm from "./contactPolicies/contactUsForm";
import Pricing from "./payment/subscriptions/pricing";
import OffCyclePaymentTable from "./payment/offCyclePayment/offCyclePaymentTable";
import UserHistory from "./userHistory/userHistory";
import UmbrellaHomepage from "./umbrella/umbrellaHomepage";
import MassInviteForm from "./players/massInviteForm";

const roles = {
  everyone: ["all"],
  leagueManagers: ["adminowner", "adminmanager"],
  admins: ["adminowner", "adminmanager", "admin"],
  teamManagers: ["playercaptain"],
  players: ["player", "playercaptain"],
  scorekeepers: ["referee"],
  ultimateScoreboard: ["superadmin", "internal"],
};

const MainSwitchRouter = () => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path="/subscriptions"
        component={SubscriptionHome}
        allowedRoles={roles.leagueManagers}
      />
      <ProtectedRoute
        exact
        path="/payments"
        component={PaymentsRedirect}
        allowedRoles={[...roles.leagueManagers, ...roles.players]}
      />
      <ProtectedRoute
        exact
        path="/history"
        component={UserHistory}
        allowedRoles={roles.players}
      />
      <ProtectedRoute
        exact
        path="/offcyclepayments"
        component={OffCyclePaymentTable}
        allowedRoles={roles.admins}
      />
      <Route exact path="/offcyclepayment" component={OffCyclePayment} />

      <ProtectedRoute
        exact
        path="/superadminview"
        component={SuperAdminHome}
        allowedRoles={roles.ultimateScoreboard}
        canAccessWithoutOrg={true}
      />

      <ProtectedRoute
        exact
        path="/gettingstarted"
        component={QuickStartForm}
        allowedRoles={roles.leagueManagers}
      />

      <Route exact path="/deeplink" component={DeepLinkRedirect} />

      <Route exact path="/privacy">
        <FinePrint openAll={true} standalone={true} />
      </Route>

      <Route exact path="/contact" component={ContactUsForm} />
      <Route exact path="/pricing" component={Pricing} />

      <Route exact path="/login" component={LoginForm} />
      <Route exact path="/loginwithcode" component={CodeEntryForm} />
      <Route exact path="/referees" component={RefereesRedirect} />

      <Route exact path="/help" component={HelpPageMain} />

      {/* signup using link from admin invitation */}
      <Route exact path="/signup" component={InvitationRegistrationPage} />

      {/* signup using link from player creation/invitation */}
      <Route exact path="/signupteam" component={InvitationRegistrationPage} />

      <Route exact path="/freeagent" component={FreeAgentRegistrationPage} />

      <Route exact path="/prepayment" component={PrepaymentSuccess} />

      <Route exact path="/unsubscribe" component={Unsubscribe} />

      <ProtectedRoute
        exact
        path="/archive"
        component={ArchiveHome}
        allowedRoles={[...roles.admins, ...roles.players]}
      />

      <Route exact path="/register" component={RegisterForm} />

      <ProtectedRoute
        exact
        path="/matchreview"
        component={MatchReviewRedirect}
        allowedRoles={[...roles.admins, ...roles.players]}
      />

      <ProtectedRoute
        exact
        path="/league"
        component={OrgRedirect}
        allowedRoles={roles.admins}
        canAccessWithoutOrg={true}
      />

      <ProtectedRoute
        exact
        path="/codeofconduct"
        component={CodeofConductInfo}
        allowedRoles={[...roles.admins, ...roles.players]}
      />

      <ProtectedRoute
        exact
        path="/vaccine"
        component={VaccineMain}
        allowedRoles={roles.everyone}
      />

      <ProtectedRoute
        exact
        path="/scorecards"
        component={LiveScorecardsMain}
        allowedRoles={[...roles.admins, ...roles.players]}
      />

      <ProtectedRoute
        exact
        path="/scoreboard"
        component={ScoreboardsHome}
        allowedRoles={[...roles.admins, ...roles.players]}
      />

      <ProtectedRoute
        exact
        path="/userinformation"
        component={UserInformationView}
        allowedRoles={roles.admins}
      />

      <ProtectedRoute
        exact
        path="/customflags"
        component={CustomFlagsView}
        allowedRoles={roles.leagueManagers}
      />

      <ProtectedRoute
        exact
        path="/divisions"
        component={DivisionRedirect}
        allowedRoles={[...roles.admins, ...roles.players]}
      />

      <ProtectedRoute
        exact
        path="/seasons"
        component={SeasonsRedirect}
        allowedRoles={[...roles.admins, ...roles.players]}
      />

      <ProtectedRoute
        exact
        path="/teams"
        component={TeamRedirect}
        allowedRoles={[...roles.admins, ...roles.players]}
      />

      <ProtectedRoute
        exact
        path="/tactics"
        component={Tactics}
        allowedRoles={roles.players}
      />

      <ProtectedRoute
        exact
        path="/fields"
        component={FieldsRedirect}
        allowedRoles={roles.admins}
      />

      <ProtectedRoute
        exact
        path="/players"
        component={PlayerRedirect}
        allowedRoles={[...roles.admins, ...roles.players]}
      />
      <ProtectedRoute
        exact
        path="/addplayers"
        component={MassInviteForm}
        allowedRoles={roles.teamManagers}
      />

      <ProtectedRoute
        exact
        path="/reports"
        component={Reports}
        allowedRoles={[...roles.admins, ...roles.players]}
      />

      <ProtectedRoute
        exact
        path="/assignedmatches"
        component={AssignedMatchRedirect}
        allowedRoles={[...roles.admins, ...roles.scorekeepers]}
      />

      <ProtectedRoute
        exact
        path="/matches"
        component={MatchRedirect}
        allowedRoles={roles.everyone}
      />

      <ProtectedRoute
        exact
        path="/create"
        component={CreateHome}
        allowedRoles={roles.admins}
      />

      <ProtectedRoute
        exact
        path="/profile"
        component={Profile}
        allowedRoles={roles.everyone}
        canAccessWithoutOrg={true}
      />

      <ProtectedRoute
        exact
        path="/messages"
        component={MessageCenter}
        allowedRoles={roles.players}
      />

      <ProtectedRoute
        exact
        path="/discounts"
        component={Discounts}
        allowedRoles={roles.admins}
      />

      <Route exact path="/not-found" component={NotFound} />

      <Route exact path="/organization" component={UmbrellaHomepage} />
      <Route exact path="/:leaguePageName" component={HomeRedirect} />
      <Route exact path="/" component={HomeRedirect} />

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default MainSwitchRouter;
