import allowables from "./allowables";
import {
  FaFootballBall,
  FaFlag,
  FaCaretSquareLeft,
  FaHands,
} from "react-icons/fa";
import { GiWingfoot, GiAmericanFootballHelmet } from "react-icons/gi";

export function footballLeaderboardDownload(leaderboard) {
  return leaderboard.map((p) => {
    return {
      name: p.name,
      teamName: p.teamName,
      extraPoint_kicking: p.events["Extra Point-kicking"],
      extraPoint_passing: p.events["Extra Point-passing"],
      extraPoint_receiving: p.events["Extra Point-receiving"],
      extraPoint_rushing: p.events["Extra Point-rushing"],
      fieldGoal_kicking: p.events["Field Goal-kicking"],
      gamesPlayed: p.events.Games,
      interceptions: p.events["Interception-interception"],
      penalties: p.events["Penalty-penalty"],
      touchdown_passing: p.events["Touchdown-passing"],
      touchdown_receiving: p.events["Touchdown-receiving"],
      touchdown_rushing: p.events["Touchdown-rushing"],
      penaltyYards: p.events.penaltyYards,
      points: p.events.points,
    };
  });
}

export function createFootballEventText(event, showPoints = true) {
  const showStyle = ["Touchdown", "Extra Point"].includes(event.eventType);

  const eventType = allowables.capLetterOne(
    event.eventType === "Defense"
      ? event.eventStyle === "fumble"
        ? "Fumble Recovery"
        : event.eventStyle === "puntReturn"
        ? "Punt/Kickoff Returned for Touchdown"
        : event.eventStyle
      : event.eventType
  );

  const text = `${event.teamName} - ${
    showStyle
      ? allowables.capLetterOne(event.eventStyle.slice(0, 4)) + "ing "
      : ""
  }${eventType}%s%${allowables.splitName(event.name)}${
    event.player2ID ? ` to ${allowables.splitName(event.player2Name)}` : ""
  }`;

  const pluralPoints = event.points === 1 ? "" : "s";

  const extra = showPoints
    ? ["interception", "fumble"].includes(event.eventStyle) &&
      Number(event.points) === 6
      ? "%s%Returned for a Touchdown"
      : event.eventStyle === "penalty"
      ? `%s%${event.points} Yard${pluralPoints}`
      : event.eventType === "Extra Point"
      ? `%s%${event.points} Point${pluralPoints}`
      : ""
    : "";

  return text + extra;
}

export function mapFootballEvents(match, events) {
  let mappedEvents = [];
  events.forEach((event, i) => {
    let newEvent = { ...event };

    newEvent.text = `${event.teamName} - ${event.eventType}%s%${event.name}${
      event.player2ID ? ` to ${event.player2Name}` : ""
    }`;

    newEvent.text = createFootballEventText(event);
    newEvent.icon =
      event.eventStyle === "passer"
        ? { icon: FaFootballBall, color: "info-text" }
        : event.eventStyle === "kicker"
        ? { icon: FaHands, color: "info-text" }
        : event.eventStyle === "rusher"
        ? { icon: GiWingfoot, color: "info-text" }
        : event.eventStyle === "safety"
        ? {
            icon: FaCaretSquareLeft,
            color: "info-text",
          }
        : event.eventType === "Defense"
        ? { icon: GiAmericanFootballHelmet, color: "main-theme" }
        : event.eventStyle === "penalty"
        ? { icon: FaFlag, color: "soccer-yellow-card-text" }
        : {};
    mappedEvents.push(newEvent);
  });
  return mappedEvents.reverse();
}
