import React, { useEffect } from "react";

import BasicModal from "../../common/pageComponents/basicModal";
import { nameButtonText } from "../../../utils/matchFunctions";
import allowables from "../../../utils/allowables";
import MiniHeader from "../../common/pageComponents/miniHeader";
import { popupStyle } from "../../../utils/styleUtil";

const MatchPopupFootball = ({
  players,
  team,
  onClick,
  popupOpen,
  closeModal,
  teamHeader,
  eventHeader,
  color,
  match,
  setPoints,
  player,
  setPlayer,
  player2,
  setPlayer2,
  type,
  setType,
  reset,
}) => {
  const score = [
    {
      id: "Touchdown",
      buttons: [
        { id: "passer", name: "Pass", class: "info" },
        { id: "rusher", name: "Run", class: "secondary" },
      ],
    },
    {
      id: "Extra Point",
      buttons: [
        { id: "passer", name: "Pass", class: "info" },
        { id: "rusher", name: "Run", class: "secondary" },
        { id: "kicker", name: "Kick", class: "dark" },
      ],
    },
    {
      id: "Field Goal",
      buttons: [{ id: "kicker", name: "Kick", class: "dark" }],
    },
  ];
  const defense = [
    {
      id: "Defense",
      buttons: [
        { id: "interception", name: "Interception", class: "light" },
        { id: "fumble", name: "Fumble Recovery", class: "light" },
        { id: "sack", name: "Sack", class: "light" },
        { id: "puntReturn", name: "Punt/Kickoff Return", class: "light" },
        { id: "safety", name: "Safety", class: "light" },
      ],
    },
  ];
  const penalty = [
    {
      id: "Penalty",
      buttons: [{ id: "penalty", name: "Penalty" }],
    },
  ];

  const types =
    eventHeader === "Score"
      ? score
      : eventHeader.includes("Defense")
      ? defense
      : penalty;

  useEffect(() => {
    if (eventHeader === "Penalty") {
      setType({ eventType: "Penalty", eventStyle: "penalty" });
      setPoints(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventHeader]);

  const renderButtons = (state, setFunction, type) => {
    const personType = [
      "interception",
      "fumble",
      "sack",
      "puntReturn",
    ].includes(type.eventStyle)
      ? "player"
      : type.eventStyle;
    return (
      <div className="col text-center">
        <h5 className="text-center">
          Select {allowables.capLetterOne(personType)}
        </h5>
        {players.map((p) => {
          return (
            <button
              key={p._id}
              className={
                "btn btn-block btn-" +
                (state && state._id === p._id ? "info" : "light")
              }
              onClick={() => setFunction(p)}
            >
              {nameButtonText(p)}
            </button>
          );
        })}
        <br />
        <button
          className={
            "btn btn-block btn-" +
            (state && state._id === "unk" ? "info" : "light")
          }
          onClick={() =>
            setFunction({
              _id: "unk",
              name: "Unknown Player",
              teamID: match[team + "TeamID"],
              teamName: match[team + "TeamName"],
            })
          }
        >
          Unknown/Unregistered Player
        </button>
      </div>
    );
  };

  const handleAlert = () => {
    if ((!type && !player) || (type.eventStyle.includes("pass") && !player2))
      return;
    onClick(team, type, player, player2);
  };
  const handleSafety = () => {
    onClick(
      team,
      { eventType: "Defense", eventStyle: "safety" },
      {
        _id: "team",
        name: match[team + "TeamName"],
        teamID: match[team + "TeamID"],
        teamName: match[team + "TeamName"],
      }
    );
  };

  const handleSkipPlayerSelect = () => {
    onClick(
      team,
      type,
      {
        _id: "unk",
        name: "Unknown Player",
        teamID: match[team + "TeamID"],
        teamName: match[team + "TeamName"],
      },
      type?.eventStyle?.includes("pass")
        ? {
            _id: "unk",
            name: "Unknown Player",
            teamID: match[team + "TeamID"],
            teamName: match[team + "TeamName"],
          }
        : null
    );
  };

  return (
    <BasicModal
      isOpen={popupOpen}
      onClose={closeModal}
      header={
        <div className={color} style={{ padding: 5, margin: 5 }}>
          <h4 className={"text-center " + color}>{teamHeader}</h4>
          <h5 className={"text-center " + color}>{eventHeader}</h5>
        </div>
      }
      alsoRunOnClose={reset}
      style={popupStyle}
    >
      <div>
        {!type &&
          types.map((t) => {
            return (
              <div key={t.id}>
                <MiniHeader>{t.id}</MiniHeader>
                <div className="form-divided-section">
                  {t.buttons.map((b, idx) => {
                    return (
                      <div key={idx} style={{ margin: 20 }}>
                        <button
                          key={b.id + t.id}
                          className={"btn btn-block btn-md btn-" + b.class}
                          onClick={() => {
                            setType({ eventType: t.id, eventStyle: b.id });
                            setPoints(
                              t.id === "Extra Point" && b.id === "kicker"
                                ? 1
                                : t.id === "Extra Point"
                                ? 2
                                : b.id === "penalty"
                                ? 10
                                : 0
                            );
                            if (b.id === "safety") {
                              setType(null);
                              handleSafety();
                            }
                          }}
                        >
                          {b.name}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        {type && type.eventStyle !== "safety" && (
          <React.Fragment>
            {eventHeader !== "Penalty" && (
              <button className="btn btn-block btn-dark btn-sm" onClick={reset}>
                Back to Type Select
              </button>
            )}
            <button
              className="btn btn-block btn-info"
              onClick={() => handleAlert()}
              disabled={
                (type.eventStyle.includes("pass") && (!player || !player2)) ||
                !player
              }
            >
              Confirm
            </button>

            <h4 className="text-center">
              <b>{type.eventType}</b>
            </h4>
            <button
              className="btn btn-block btn-sm btn-secondary"
              onClick={handleSkipPlayerSelect}
            >
              Skip Player Selection
            </button>
            <div className="row">
              {renderButtons(player, setPlayer, type)}
              {type.eventStyle.includes("pass") &&
                renderButtons(player2, setPlayer2, {
                  eventStyle: "receiver",
                })}
            </div>
          </React.Fragment>
        )}
      </div>
    </BasicModal>
  );
};

export default MatchPopupFootball;
