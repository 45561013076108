import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import toast from "../../utils/toast";
import orderBy from "lodash/orderBy";
import PlayersTable from "../players/playersTable";
import allowables from "../../utils/allowables";
import CustomConfirm from "../common/customs/customConfirm";
import CustomPrompt from "../common/customs/customPrompt";
import HeaderContext from "../../context/headerContext";
import { navigateTo, navigateBack, CLink } from "../common/customs/customLinks";
import MailingList from "../common/dataSorting/mailingList";
import TeamLogoEditor from "./teamLogoEditor";
import SideBySideView from "./../common/pageComponents/sideBySideView";
import WaiverWireInfo from "./waiverWireInfo";
import WarningHeader from "../common/pageComponents/warningHeader";
import InfoComponent from "../common/info/infoComponent";
import ImageHeader from "../common/pageComponents/imageHeader";
import Avatar from "../common/pageComponents/avatarEditor";

class TeamForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      name: "",
      divisionName: "",
      teamColor1: "",
      teamColor2: "",
      seed: "",
      groupName: "",
      teamAbbreviation: "",
      teamCaptainName: "",
      teamCaptainID: "",
    },
    divisions: [],
    errors: {},
    baseState: {
      name: "",
      divisionName: "",
      teamColor1: "",
      teamColor2: "",
      seed: "",
      groupName: "",
      teamAbbreviation: "",
      teamCaptainName: "",
      teamCaptainID: "",
    },
    source: "edit",
    players: [],
    captain: false,
    deleteThisOpen: false,
    deleteOpen: false,
    deleteDialog: "",
    selectedPlayer: null,
    emailOpen: false,
    moveToFreeAgentsOpen: false,
    waiverCancelOpen: false,
    email: "",
    teamCaptainInfo: null,
    imageEditOpen: false,
  };

  schema = {
    name: Joi.string().required().min(3).max(50).label("Team Name"),
    divisionName: Joi.string().required().max(50).label("Division Name"),
    teamColor1: Joi.string().allow("").max(50).label("Team Color 1"),
    teamColor2: Joi.string().allow("").max(50).label("Team Color 2"),
    seed: Joi.number().integer().optional().allow("").label("Seed").min(1),
    groupName: Joi.string().optional().allow("").max(50).label("Group Name"),
    teamAbbreviation: Joi.string()
      .optional()
      .allow("")
      .max(6)
      .label("Team Name Abbreviation"),
    teamCaptainName: Joi.string().optional().allow("").label("Team Captain"),
    teamCaptainID: Joi.string().optional().allow("").label("Select Captain"),
  };

  async loadPlayers(team) {
    if (!team) team = this.state.baseState;
    let playersRes = await this.props.getPlayersByTeam(this.props.teamID, {
      callback: this.indicateProgress,
      bar: 2,
    });
    if (playersRes.status === 200) {
      // flatten userID field for selecting team captain
      let teamCaptainInfo;
      const players = playersRes.data.map((p) => {
        if (team.teamCaptainID === p.userID?._id) teamCaptainInfo = p;
        return { ...p, captainID: p.userID?._id };
      });

      this.setState({ players, teamCaptainInfo });
    } else toast.error(playersRes.data);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.teamID !== this.props.teamID ||
      prevProps.source !== this.props.source
    )
      this.componentDidMount();
  }
  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1, 1, 1]);
    const user = this.props.getCurrentUser();
    const teamID = this.props.teamID;
    let { data: divisions } = await this.props.getDivisions({
      callback: this.indicateProgress,
      bar: 0,
    });
    divisions = orderBy(divisions, ["name"], ["asc"]);
    this.setState({ divisions });

    if (teamID === "new") {
      if (user.role.includes("admin") || user.role.includes("captain"))
        return this.context.setLoading(false);
      else return navigateBack(this.props.history, this.context);
    }
    const { data: team, status } = await this.props.getTeam(teamID, {
      callback: this.indicateProgress,
      bar: 1,
    });

    if (status !== 200 && teamID !== "new")
      return this.props.history.replace("/not-found");

    let { source } = this.props;
    if (this.props.location.search.includes("info")) source = "info";

    await this.loadPlayers(team);

    if (
      source === "edit" &&
      !user.role.includes("admin") &&
      user._id !== team.teamCaptainID
    ) {
      navigateBack(this.props.history, this.context);
    }

    const captain = team.teamCaptainID === user._id ? true : false;

    this.setState({
      data: this.mapToViewModel(team),
      baseState: team,
      source,
      captain,
    });
    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  mapToViewModel(team) {
    return {
      name: team.name,
      divisionName: team.divisionName || "",
      teamColor1: team.teamColor1 || "",
      teamColor2: team.teamColor2 || "",
      groupName: team.groupName || "",
      seed: team.seed || "",
      teamAbbreviation: team.teamAbbreviation || "",
      teamCaptainName: team.teamCaptainName || "",
      teamCaptainID: team.teamCaptainID || "",
    };
  }

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  handleDeleteThis = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const response = await this.props.deleteTeam(this.props.teamID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      return navigateBack(this.props.history, this.context);
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  setDeleteDialog = (player) => {
    this.setState({
      deleteDialog: `Are you sure you want to delete ${allowables.splitName(
        player.name
      )}`,
      deleteOpen: true,
      selectedPlayer: player,
    });
  };

  handleDelete = async () => {
    const player = this.state.selectedPlayer;
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const response = await this.props.deletePlayer(player._id, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      toast.success(
        `${allowables.splitName(
          this.state.selectedPlayer.name
        )} deleted successfully`
      );
      const playersResponse = await this.props.getPlayersByTeam(
        this.props.teamID,
        {
          callback: this.indicateProgress,
          bar: 1,
        }
      );
      if (playersResponse.status === 200)
        this.setState({ players: playersResponse.data });
      else toast.error(playersResponse.data);
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  handleSwitchToEditMode = () => {
    const editPath =
      this.props.history.location.pathname +
      this.props.history.location.search.replace("info&", "");
    navigateTo(editPath, this.props.history, this.context);
    this.componentDidMount();
  };

  clickLinkOne = (player) => {
    this.setState({ emailOpen: true, selectedPlayer: player });
  };

  handleClickLink = async (email) => {
    const { selectedPlayer: player } = this.state;
    const ex = Joi.validate(
      { email },
      { email: Joi.string().required().email().label("Email") }
    );
    if (ex.error) return toast.error(ex.error.details[0].message);
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const response = await this.props.sendLink(email, player);
    if (response.status === 200) {
      toast.success(response.data);
      await this.loadPlayers();
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  mapMailingList = () => {
    const players = [...this.state.players];
    const users = players.filter((p) => p.profileID).map((p) => p.profileID);
    return users;
  };

  handleMoveToFreeAgents = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const res = await this.props.moveToFreeAgents(
      this.state.selectedPlayer?._id,
      {
        callback: this.indicateProgress,
        bar: 0,
      }
    );
    if (res.status === 200) {
      toast.success(res.data);
      this.componentDidMount();
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  handleCancelBid = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const res = await this.props.cancelBid({
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) {
      toast.success(res.data);
      this.componentDidMount();
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  doSubmit = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const teamID = this.props.teamID;
    const user = this.props.getCurrentUser();
    let data = { ...this.state.data };
    if (this.state.baseState.name === "Free Agents") {
      toast.error("You cannot make changes to the Free Agents team");
      return this.context.setLoading(false);
    }
    const { org } = this.props;
    let divisionID = this.state.divisions.find(
      (d) => d.name === data.divisionName
    )?._id;
    if (!divisionID) {
      this.context.setLoading(false);
      return toast.error("Please select a division for this team", {
        icon: () => (
          <img
            src="/logos/iconOnSplash.png"
            alt="usb-square"
            width={20}
            height={20}
            style={{ borderRadius: 5 }}
          />
        ),
      });
    }
    if (data.teamCaptainID) {
      if (data.teamCaptainID === "n/a") {
        data.teamCaptainID = null;
        data.teamCaptainName = null;
      } else
        this.state.players.forEach((p) => {
          if (p.captainID === data.teamCaptainID) data.teamCaptainName = p.name;
        });
    }
    data.divisionID = divisionID;
    data.orgID = org._id;
    data.orgName = org.name;
    data.sport = org.sport;
    if (user.role.includes("captain")) {
      data.teamCaptainName = user.name;
      data.teamCaptainID = user._id;
    }

    const response = await this.props.saveTeam(teamID, data, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      return this.props.history.replace("/teams");
    }
    this.setState({ apiError: response.data });
    toast.error(response.data);
    this.context.setLoading(false);
  };

  render() {
    const {
      divisions,
      source,
      players,
      baseState,
      captain,
      selectedPlayer,
      deleteThisOpen,
      deleteOpen,
      deleteDialog,
      emailOpen,
      moveToFreeAgentsOpen,
      waiverCancelOpen,
      teamCaptainInfo,
    } = this.state;
    const disabled = source === "info" ? "disabled" : "";
    const user = this.props.getCurrentUser();
    const urlBase = window.location.href.substring(
      0,
      window.location.href.length - (source === "edit" ? 30 : 35)
    );
    let playersWithUserIDs = players.filter((p) => p.userID);
    const currentDivision =
      baseState && baseState.divisionID
        ? divisions.find((d) => d._id === baseState.divisionID)
        : null;

    return (
      <div className="centered-small-input-area">
        {this.props.org?.misc?.userImages === "Yes" ? (
          <div>
            {user &&
            user.role.includes("captain") &&
            user.teamID === this.props.teamID ? (
              <TeamLogoEditor team={baseState} />
            ) : user?.role.includes("admin") && !baseState.logo ? (
              <div>
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() =>
                    this.setState({
                      imageEditOpen: !this.state.imageEditOpen,
                    })
                  }
                >
                  Upload Logo for Team
                </button>
                {this.state.imageEditOpen ? (
                  <div>
                    <p>
                      Upload a logo for this team. If the team captain uploads
                      their own image it will override this one.
                    </p>
                    <Avatar
                      uploadFunction={(image) =>
                        this.props.manageImage("team", baseState._id, image)
                      }
                      height={150}
                      width={150}
                      saveTitle="Save Team Logo"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            <ImageHeader
              isNew={this.props.teamID === "new"}
              team={baseState}
              org={this.props.org}
            />
          </div>
        ) : null}

        {divisions.length === 0 ? (
          <div>
            <WarningHeader>
              You must create at least one division before adding a team
            </WarningHeader>
            <CLink
              path="/divisions?q=new"
              button={true}
              buttonStyle="btn-md btn-block btn-info"
            >
              Add New Division
            </CLink>
          </div>
        ) : (
          <div>
            {/* // warn if too many players on roster */}
            {currentDivision &&
            players.length > 0 &&
            currentDivision.maxPlayersPerTeam > 0 &&
            currentDivision.maxPlayersPerTeam < players.length ? (
              <WarningHeader>
                There are currently more players on this roster than allowed by
                the division settings.
              </WarningHeader>
            ) : null}

            {disabled ? (
              <InfoComponent
                userRole={user.role}
                data={this.state.baseState}
                fields={[
                  {
                    name: "Division",
                    property: "divisionName",
                    link: `divisions?info&q=${this.state.baseState.divisionID}`,
                    linkIfRole: "admin",
                  },
                  {
                    name: "Captain",
                    property: "teamCaptainName",
                    type: "name",
                    link: `players?info&q=${teamCaptainInfo?._id}`,
                  },
                  { name: "Abbrevation", property: "teamAbbreviation" },
                  {
                    name: "Home Color",
                    property: "teamColor1",
                    type: "color",
                  },
                  {
                    name: "Away Color",
                    property: "teamColor2",
                    type: "color",
                  },
                  { name: "Group", property: "groupName" },
                  { name: "Seed", property: "seed" },
                ]}
                handleSwitchToEditMode={
                  user.role.includes("admin") ||
                  baseState.teamCaptainID === user._id
                    ? this.handleSwitchToEditMode
                    : null
                }
              />
            ) : (
              this.renderWholeForm(
                "Team",
                "/teams",
                {
                  listHeader: "Required Fields",
                  listItems: ["Team Name", "Division Name"],
                  text: `A team must be tied to a division at all times.
          Once you create the team and a player, invite a user to register as the player
           and you can make them the team captain who can create, invite and edit the players.
           They will not have access to change the team information.`,
                },
                <div>
                  {this.renderInput(
                    "name",
                    "* Team Name",
                    "autofocus",
                    "text",
                    disabled
                  )}
                  {this.renderSelect(
                    "divisionName",
                    "* Division Name",
                    divisions,
                    disabled,
                    {
                      header: "Division Name",
                      text: `This field is required. A team must be tied to a division at all times.
                If you do not know which division a team will be in you can create a division to store all teams until you are ready to move them into the correct place.`,
                      direction: "right",
                      className: "icon-mobile",
                    },
                    null,
                    user.role.includes("admin")
                      ? {
                          onClick: () =>
                            navigateTo(
                              "/divisions?q=new",
                              this.props.history,
                              this.context
                            ),
                          tooltipText: "Add New Division",
                          icon: "add",
                          class: "btn-info",
                        }
                      : null
                  )}
                  {this.renderInput(
                    "teamAbbreviation",
                    "Team Name Abbreviation (up to six characters)",
                    "",
                    "text",
                    disabled,
                    "",
                    "",
                    {
                      header: "Team Abbrviation",
                      text: `This will be what is displayed on a playoff bracket.
                It can be up to six characters long.
                If this field is left blank the first six characters from the Team Name will be used for abbreviation.`,
                      direction: "right",
                      className: "icon-mobile",
                    }
                  )}
                  {players.length > 0 &&
                  baseState._id &&
                  source === "edit" &&
                  user.role.includes("admin")
                    ? this.renderSelectByValueOption(
                        "teamCaptainID",
                        "Select Team Captain",
                        [
                          {
                            _id: "n/a",
                            captainID: "n/a",
                            name: "No Team Captain",
                          },
                          ...playersWithUserIDs,
                        ],
                        "captainID",
                        "",
                        {
                          header: "Team Captain",
                          text: `You can select any player on the team who also has a user attached to be team captain.
                  For more information on the role that a team captain plays visit the help pages.`,
                          direction: "right",
                          className: "icon-mobile",
                        }
                      )
                    : null}
                  <SideBySideView
                    Components={[
                      this.renderColorPicker(
                        "teamColor1",
                        "Team Color 1",
                        disabled,
                        {
                          header: "Team Colors",
                          text: `Team colors are for informational purposes only so that players can plan their jersey choices before attending a match.`,
                          direction: "right",
                          className: "icon-mobile",
                        },
                        false,
                        "compact"
                      ),
                      this.renderColorPicker(
                        "teamColor2",
                        "Team Color 2",
                        disabled,
                        {
                          header: "Team Colors",
                          text: `Team colors are for informational purposes only so that players can plan their jersey choices before attending a match.`,
                          direction: "right",
                          className: "icon-mobile",
                        },
                        false,
                        "compact"
                      ),
                    ]}
                  />
                  {user.role.includes("admin")
                    ? this.renderInput(
                        "groupName",
                        "Group Name",
                        "",
                        "text",
                        disabled,
                        "",
                        "",
                        {
                          header: "Group Name",
                          text: `You only need to add a Group Name if you are going to be using the group match format.
              To create a group match the Teams must be in the same Division as well as the same group.
              You can name your groups in any way you would like.
              Group naming is case sensitive, a team in a group named Apple is not in the same group as a team in a group named apple.`,
                          direction: "up",
                          className: "icon-mobile",
                        }
                      )
                    : null}
                  {user.role.includes("admin")
                    ? this.renderInput(
                        "seed",
                        "Seed",
                        "",
                        "number",
                        disabled,
                        "",
                        "1",
                        {
                          header: "Seed",
                          text: `Seed is used to rank teams when using the bracket creation tool.
              Brackets are created using a standard bracket seeding, e.g. in the first round of an eight team tournament seed 1 will play 8, 2 will play 7, etc.`,
                          direction: "up",
                          className: "icon-mobile",
                        }
                      )
                    : null}
                </div>
              )
            )}
            {source === "info" ? (
              <div>
                {captain ? (
                  <WaiverWireInfo
                    teamWaiverWire={baseState.waiverWire}
                    onCancelBid={() => this.toggleModal("waiverCancelOpen")}
                  />
                ) : null}
                {user.teamID === this.props.teamID &&
                user.sport.toLowerCase().includes("soccer") ? (
                  <CLink
                    button={true}
                    buttonStyle="btn-sm btn-secondary btn-block"
                    path="/tactics"
                  >
                    Team Tactics
                  </CLink>
                ) : null}
                <br />
                <div className="row">
                  <div className="col">
                    <h4>
                      <small className="text-muted">Team Captain:</small>{" "}
                      {teamCaptainInfo ? (
                        <CLink path={`/players?info&q=${teamCaptainInfo._id}`}>
                          {allowables.splitName(teamCaptainInfo.name)}
                        </CLink>
                      ) : (
                        "Not Assigned"
                      )}
                    </h4>
                  </div>
                  {user &&
                  (user.role.includes("captain") ||
                    user.role.includes("admin")) ? (
                    <div className="col">
                      <MailingList
                        users={this.mapMailingList()}
                        buttonTitle="Create Mailing List"
                        secondaryTitle={""}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {captain || user.role.includes("admin") ? (
                    <div className="col text-left">
                      <CLink
                        path={"/players?q=new"}
                        button={true}
                        buttonStyle="btn-md btn-info"
                      >
                        Add New Player
                      </CLink>
                    </div>
                  ) : null}
                  {captain ? (
                    <div className="col text-right">
                      <CLink
                        path={"/inviteplayers"}
                        button={true}
                        buttonStyle="btn-md btn-secondary"
                      >
                        Mass Invite Players
                      </CLink>
                    </div>
                  ) : null}
                </div>
                {players.length > 0 ? (
                  <PlayersTable
                    playersByPage={players}
                    captain={captain}
                    backgroundKey={"suspendedFlag"}
                    backgroundStyle={"bg-danger text-white"}
                    thisID={user.playerID}
                    onDelete={this.setDeleteDialog}
                    link={true}
                    onClickLink={this.clickLinkOne}
                    team={baseState}
                    urlBase={urlBase}
                    teamForm={true}
                    org={this.props.org}
                    onMoveToFreeAgents={(player) =>
                      this.setState({
                        moveToFreeAgentsOpen: true,
                        selectedPlayer: player,
                      })
                    }
                    getCurrentUser={this.props.getCurrentUser}
                  />
                ) : (
                  <p>There are no players registered for this team.</p>
                )}
              </div>
            ) : null}
            <CustomConfirm
              dialog={deleteDialog}
              isOpen={deleteOpen}
              close={this.toggleModal}
              id="deleteOpen"
              yesNo={true}
              focused={true}
              callback={this.handleDelete}
            />
            <CustomConfirm
              dialog={`Are you sure you want to delete ${this.state.baseState.name}?`}
              isOpen={deleteThisOpen}
              callback={this.handleDeleteThis}
              close={this.toggleModal}
              id={"deleteThisOpen"}
              focused={true}
            />
            <CustomConfirm
              dialog={`Move player ${allowables.splitName(
                selectedPlayer?.name
              )} to Free Agents?%Once the player is moved they will be on the waiver wire and unrecruitable for ${
                this.props.org.misc?.waiverWireLength || 1
              } day(s).%Are you sure?`}
              isOpen={moveToFreeAgentsOpen}
              callback={this.handleMoveToFreeAgents}
              close={this.toggleModal}
              id={"moveToFreeAgentsOpen"}
              focused={true}
              yesNo={true}
              split="%"
            />
            <CustomPrompt
              dialog="Enter the player's email"
              isOpen={emailOpen}
              close={this.toggleModal}
              id="emailOpen"
              callback={this.handleClickLink}
            />
            <CustomConfirm
              dialog="Cancel your waiver wire bid?%You will be free to place another bid."
              isOpen={waiverCancelOpen}
              close={this.toggleModal}
              id="waiverCancelOpen"
              callback={this.handleCancelBid}
              split="%"
              yesNo={true}
            />
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
      </div>
    );
  }
}

export default TeamForm;
