import React, { Component } from "react";

import { getCurrentUser } from "../../services/userService";
import CombinedMatch from "./combinedMatch";
import { navigateBack } from "../common/customs/customLinks";
import HeaderContext from "../../context/headerContext";
import { getMatch, submitScorecard } from "../../services/matchService";
import { getPlayersForMatch } from "../../services/playerService";
import { getTeamsForMatch } from "../../services/teamService";
import {
  removeEventFromLiveScorecard,
  removeLiveScorecard,
  sendEventToLiveScorecard,
  startLiveScorecard,
  syncLiveScorecard,
} from "../../services/liveScoreService";

class AssignedMatchRedirect extends Component {
  static contextType = HeaderContext;
  state = {
    loading: true,
    matchID: "",
    user: {},
  };

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) this.setup();
  }

  setup = () => {
    this.context.setLoading(true);
    const user = getCurrentUser();
    const query = this.props.location.search.slice(1).split("&");
    let matchID = query.find((q) => q.split("=")[0] === "q");
    matchID = matchID && matchID.split("=")[1];
    if (!user || user.role.includes("player"))
      navigateBack(this.props.history, this.context);
    this.setState({ loading: false, matchID, user });
  };

  render() {
    return this.state.loading ? null : this.state.user.role &&
      !this.state.user.role.includes("player") ? (
      <CombinedMatch
        {...this.props}
        matchID={this.state.matchID}
        getMatch={getMatch}
        submitScorecard={submitScorecard}
        getPlayersForMatch={getPlayersForMatch}
        getTeamsForMatch={getTeamsForMatch}
        removeEventFromLiveScorecard={removeEventFromLiveScorecard}
        removeLiveScorecard={removeLiveScorecard}
        sendEventToLiveScorecard={sendEventToLiveScorecard}
        startLiveScorecard={startLiveScorecard}
        syncLiveScorecard={syncLiveScorecard}
      />
    ) : null;
  }
}

export default AssignedMatchRedirect;
